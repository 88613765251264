import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../../../apiConfig";

import { useNavigate } from "react-router-dom";

const CreateTeam = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [errorMessage, setErrorMessgae] = useState("")

  const getUsers = async () => {
  
    try {
      const response = await fetch(`${API_BASE_URL}/api/accounts/user/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      const data = await response.json();
      setUsers(data);
      console.log("Users", data);
    } catch (error) {
      console.log("an error occurred while fetching users");
    } finally {
     
    }
  };

  const [formData, setFormData] = useState({
    team_name: "",
    user: "",
  });

  const handleChange = async (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/accounts/create_team/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },

          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        console.log("created succesifully");
        navigate("/main/teams");

        setTimeout(() => {}, 3000);
      } else {
        console.log("An error occured while submitting data to the server");
        setErrorMessgae("This team already exist ,please try again")
        setTimeout(() => {
          setErrorMessgae("")
        }, 3000);
      }
    } catch (error) {
      console.log("An error occured while submitting data");
    }
  };

  useEffect(() => {
    getUsers()
  }, [])


  useEffect(() => {
    const timer = setTimeout(() => {
      setErrorMessgae("")
    }, 2000);

    return () => clearTimeout(timer)
  })

  return (
    <div className="content-wrapper" style={{minHeight: "90vh"}}>
      <section className="content-header ">
        <div className="container-fluid ">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h5>Teams </h5>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/main/dashboard">Home</a>
                </li>
                <li className="breadcrumb-item active">Add Team</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-12 ">
              <div className="card">
                <div className="card-body ">
                  <div className="container   mt-5">
                    {errorMessage && <p className=" rounded p-1 bg-danger">{errorMessage}</p>}
                    
                    <div className="row ">
                      <div className="col-sm-6">
                        <h3 className="card-title text-bold">Add Team</h3>
                      </div>
                      <div className="col-sm-6 text-right"></div>
                    </div>
                  </div>

                  <div className="container border rounded mt-5">
                    <div>
                      <form
                        onSubmit={handleSubmit}
                        style={{ margin: "0 10px" }}
                      >
                        <div className="mt-4">
                          <div>
                            <h6 className=" text-bold">Team name</h6>
                            <input
                              type="text"
                              className="form-control mb-2 rounded"
                              name="team_name"
                              value={formData.team_name}
                              onChange={handleChange}
                            />
                          </div>

                          <div>
                            <h6 className="text-bold">Team owner</h6>
                            <select
                              name="user"
                              value={formData.user}
                              onChange={handleChange}
                              className="form-control mb-2 rounded"
                            >
                              <option value="">Select a user</option>
                              {users.map((user, index) => (
                                <option key={index} value={user.id}>
                                  {user.username}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="card-footer">
                          <button
                            type="submit"
                            className="btn app__bgColor text-white  text-bold btn-sm"
                          >
                            Save Details
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-secondary btn-sm"
                            style={{ float: "right" }}
                            onClick={() => {
                              window.location.href = "/main/teams";
                            }}
                          >
                            Close Form
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CreateTeam;
