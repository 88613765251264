import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../../../apiConfig";
import DataTable from "react-data-table-component";

import TableLoader from "../../common/TableLoader";
import { Link } from "react-router-dom";
import Spinner from "../../common/Spinner";
import DeleteConfirm from "../../common/DeleteConfirm";
import useTokenExpiration from "../../../useTokenExpiration";

function TeamMembers() {
  const [teamMembers, setTeamMembers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [selectedTeamMemberId, setSelectedTeamMemberId] = useState(null) 
  const USERROLE = localStorage.getItem("role")
  console.log(USERROLE)
  const { errorMessage, handleTokenExpiry } = useTokenExpiration();



  const [show, setShow] = useState(false);

//   const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false)



  const handleConfirmDelete = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/accounts/delete_team_member/${selectedTeamMemberId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );


      if(response.status === 401) {
        handleTokenExpiry()
        return
      }

      if (response.ok) {
        getTeamMembers();
      } else {
        console.log("server error");
      }
    } catch (error) {
      console.log("An error occured while deleting team member", error);
    }
    setTimeout(() => {
      setShow(false);
    }, 1000);
  };


  const handleDelete = (teamMemberId) => {
    setSelectedTeamMemberId(teamMemberId);
  };
 

  function convertToCSV(data) {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((row) => Object.values(row).join(","));
    return [header, ...rows].join("\n");
  }

  const getTeamMembers = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/api/accounts/get_team_members/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      if(response.status === 401) {
        handleTokenExpiry()
        return
      }


      const data = await response.json();
      setTeamMembers(Array.isArray(data) ? data : []);
      console.log("Teams members", data);
    } catch (error) {
      console.log("an error occurred while fetching team members");
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredteamMembers = teamMembers.filter((teamMember) => {
    return (
      teamMember.first_name.toLowerCase().includes(searchText.toLowerCase()) ||
      teamMember.last_name.toLowerCase().includes(searchText.toLowerCase())||
      teamMember.team.toLowerCase().includes(searchText.toLowerCase())||
      teamMember.designation.toLowerCase().includes(searchText.toLowerCase())
    );
  });

  const columns = [
    
    {
      name: "First name",
      selector: (row) => `${row.first_name}`,
      sortable: true,
      className: "bold-header",
    },
    {
      name: "Last name",
      selector: (row) => `${row.last_name} `,
      sortable: true,
      className: "bold-header",
    },

    {
        name: "Team",
        selector: (row) => `${row.team} `,
        sortable: true,
        className: "bold-header",
      },

      {
        name: "Email Address",
        selector: (row) => `${row.email} `,
        sortable: true,
        className: "bold-header",
      },

     

    {
        cell: (row) => (
          <div className="dropdown">
            <button
              className="btn  btn-sm text-xs"
              type="button"
              id={`dropdownMenuButton-${row.id}`}
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="fa fa-ellipsis-v"></span>
            </button>
            <div
              className="dropdown-menu text-xs"
              aria-labelledby={`dropdownMenuButton-${row.id}`}
            >
             
                <>
               
                  <a
                    className="text-white"
                    href={`/main/update_team_member/${row.id}`}
                  >
                    <button className="dropdown-item">Edit</button>
                  </a>
  
                  <button
                    className="dropdown-item"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleDelete(row.id);
                      handleShow();
                    }}
                  >
                    Delete
                  </button>
                </>
          
            </div>
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        minWidth: "60px", // Set a minimum width for the dropdown button
      },
  ];

  useEffect(() => {
 

    const timer = setTimeout(() => {
        getTeamMembers();
        setIsLoading(false)
    }, 1000);

    return () => clearTimeout(timer)
    
  }, []);

  return (
    <>
    {isLoading ?(
        <Spinner />
    ) : (
        <div className="content-wrapper" style={{minHeight: "90vh"}}>
        <section className="content-header ">
          <div className="container-fluid ">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h5>Teams Members</h5>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/main/dashboard">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Teams Members</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid ">
            <div className="row">
              <div className="col-12 ">
                <div className="card">
                  <div className="card-body ">
                    <div className="container   mt-5">
                      <div className="row ">
                        <div className="col-sm-6">
                          <h3 className="card-title text-bold">Teams Members</h3>
                        </div>
                        <div className="col-sm-6 text-right">
  
                      

                          {(USERROLE === 'Team Lead'  || USERROLE === 'Admin') && (
                                 <>
                                   <Link
                        to={`/main/add_team_member`}
                            type="button"
                            className="btn btn-outline-primary btn-xs"
                            style={{ float: "right", marginRight: "10px" }}
                          >
                            Add member 
                          </Link>
                                 {/* <Link
                                  to={`/main/members_uplaod`}
                                  type="button"
                                  className="btn btn-outline-primary btn-xs"
                                  style={{ float: "right", marginRight: "10px" }}
                                >
                                  upload members
                                </Link> */}
                                
                                <button
                                  type="button"
                                  className="btn btn-outline-primary btn-xs"
                                  style={{ float: "right", marginRight: "10px" }}
                                  onClick={() => {
                                    const csv = teamMembers.map((teamMember) => ({
                                      id: `${teamMember.id}`,
                                      first_name: `${teamMember.first_name} `,
                                      last_name: `${teamMember.last_name}`,
                                      team: `${teamMember.team}`,
                                      designation: teamMember.designation
                                    }));
                                    const csvData = convertToCSV(csv);
                                    const blob = new Blob([csvData], {
                                      type: "text/csv",
                                    });
                                    const url = window.URL.createObjectURL(blob);
                                    const a = document.createElement("a");
                                    a.setAttribute("href", url);
                                    a.setAttribute("download", "users.csv");
                                    a.click();
                                  } }
                                >
                                    Export to CSV
                                  </button></>
           
                          )}
                         
  
                         
  
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="Search..."
                            value={searchText}
                            onChange={handleSearch}
                            style={{
                              width: "200px",
                              float: "right",
                              marginRight: "10px",
                            }}
                          />
                        </div>
                      </div>
                    </div>


                    {errorMessage && (
                           <div className=" container mt-2 bg-danger">
                           <p className="p-2 ">{errorMessage}</p>
                           </div>
                      )}
  
                    <div className="container border rounded mt-5">
                      <div>
                        {loading}
                        <DataTable
                          columns={columns}
                          data={filteredteamMembers}
                          fixedHeader
                          responsive={true}
                          selectableRows
                          progressPending={loading}
                          progressComponent={<TableLoader />}
                          onTableUpdate={() => setLoading(false)} // Reset loading state when table updates
                          striped // Enable row striping
                          highlightOnHover // Highlight row
                          pagination // Paginate
                        />
                      </div>

                      {show && (
                        <DeleteConfirm
                          handleConfirmDelete={handleConfirmDelete}
                          itemName={
                            teamMembers.find(
                              (teamMember) => teamMember.id === selectedTeamMemberId
                            )?.first_name 
                          }
                          handleClose={handleClose}
                          handleShow={handleShow}
                          show={show}
                        />
                      )}


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )}
   
    </>
  );
}

export default TeamMembers;
