import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../../../apiConfig";
import { Link, useParams } from "react-router-dom";
import Spinner from "../../common/Spinner";
import DeleteConfirm from "../../common/DeleteConfirm";
import PaymentModal from "../Payment/PaymentModal";

function AssessmentInfo() {
  const [assessment, setAssessment] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [hasPaid, setHasPaid] = useState(false);
  const [selectedSurveyId, setSelectedSurveyId] = useState(null);
  const [show, setShow] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const USERROLE = localStorage.getItem("role");

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const params = useParams();

  const handleConfirmDelete = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/survey/delete_survey/${selectedSurveyId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      if (response.ok) {
        getAssessment();
      } else {
        console.log("Server error");
      }
    } catch (error) {
      console.log("An error occurred while deleting survey", error);
    } finally {
      setShow(false);
    }
  };

  const handleDelete = (surveyId) => {
    setSelectedSurveyId(surveyId);
    handleShow();
  };

  function convertToCSV(data) {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((row) => Object.values(row).join(","));
    return [header, ...rows].join("\n");
  }

  const getAssessment = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/api/survey/get_assessment_teamlead/${params.id}/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setAssessment(data);
        setHasPaid(true);
      } else if (response.status === 403) {
        setHasPaid(false);
      }
    } catch (error) {
      console.log("An error occurred while fetching surveys");
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    getAssessment();
  }, []);

  if (loading) {
    return <Spinner />;
  }

  if (!hasPaid) {
    return <PaymentModal />;
  }

  const totalPages = Math.ceil((assessment?.surveys?.length || 0) / itemsPerPage);

  const filteredSurveys = assessment?.surveys?.filter((survey) =>
    survey.member.toString().toLowerCase().includes(searchText.toLowerCase())
  ) || [];

  const aggregateData = (data) => {
    const aggregated = data.reduce((accumulator, currentValue) => {
      const memberName = `${currentValue.first_name} ${currentValue.last_name}`

      if(!accumulator[memberName]) {
        accumulator[memberName] = {
          member: memberName,
          total_competence: 0,
          total_clarity: 0,
          total_credibility: 0,
          total_connectivity: 0
        }
      }

      accumulator[memberName].total_competence += currentValue.competence
      accumulator[memberName].total_clarity += currentValue.clarity
      accumulator[memberName].total_credibility += currentValue.credibility
      accumulator[memberName].total_connectivity += currentValue.connectivity

      return accumulator
    }, {})

    return Object.values(aggregated)
  }


  const aggregatedData = aggregateData(filteredSurveys)

  return (
    <div className="content-wrapper" style={{ minHeight: "90vh" }}>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h5>Assessment</h5>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/main/dashboard">Home</a>
                </li>
                <li className="breadcrumb-item active">Assessment</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-6">
                        <h3 className="card-title text-bold">Assessment</h3>
                      </div>
                      <div className="col-sm-6 text-right">
                        {/* <Link
                          to={`/main/add_survey`}
                          type="button"
                          className="btn btn-outline-primary btn-xs"
                          style={{ float: "right", marginRight: "10px" }}
                        >
                          Take Assessment
                        </Link> */}
                        {(USERROLE === "Team Lead" || USERROLE === "Admin") && (
                          <button
                            type="button"
                            className="btn btn-outline-primary btn-xs"
                            style={{ float: "right", marginRight: "10px" }}
                            onClick={() => {
                              const csv = filteredSurveys.map((survey) => ({
                                Member: survey.member,
                                competence: survey.competence,
                                credibility: survey.credibility,
                                clarity: survey.clarity,
                                connectivity: survey.connectivity,
                              }));
                              const csvData = convertToCSV(csv);
                              const blob = new Blob([csvData], { type: "text/csv" });
                              const url = window.URL.createObjectURL(blob);
                              const a = document.createElement("a");
                              a.setAttribute("href", url);
                              a.setAttribute("download", "surveys.csv");
                              a.click();
                            }}
                          >
                            Export to CSV
                          </button>
                        )}

                        <input
                          type="text"
                          className="form-control form-control-sm"
                          placeholder="Search..."
                          value={searchText}
                          onChange={handleSearch}
                          style={{
                            width: "200px",
                            float: "right",
                            marginRight: "10px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="container">
                    <table className="table table-bordered w-100">
                      <thead>
                        <tr>
                          <th className="text-primary">Member</th>
                          <th className="text-primary">Competence</th>
                          <th className="text-primary">Credibility</th>
                          <th className="text-primary">Clarity</th>
                          <th className="text-primary">Connectivity</th>
                       
                        </tr>
                        <tr>
                        
                          
                          <td>
                            How good are they at what they do? (0 - 10) 10 is
                            the highest
                          </td>
                          <td>
                            How much do you trust them as a person? (0 - 10) 10
                            is the highest
                          </td>
                          <td>
                            How well do they communicate about their area of
                            expertise? (0 - 10) 10 is the highest
                          </td>
                          <td>
                            How well connected are they? (0 - 10) 10 is the
                            highest
                          </td>
                          <td></td>
                        </tr>
                      </thead>
                      <tbody>
                        {aggregatedData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((survey, index) => (
                          <tr key={index}>
                            <td>{survey.member}</td>
                            <td>{survey.total_competence}</td>
                            <td>{survey.total_credibility}</td>
                            <td>{survey.total_clarity}</td>
                            <td>{survey.total_credibility}</td>
                         
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    <nav style={{ display: "flex", alignItems: "center", justifyContent: 'end' }} aria-label="Page navigation example">
                      <ul className="pagination">
                        <li className="page-item" onClick={handlePrevPage}><a className="page-link" href="#">Previous</a></li>
                        <li className="page-item"><a className="page-link" href="#">Page {currentPage} of {totalPages}</a></li>
                        <li className="page-item" onClick={handleNextPage}><a className="page-link" href="#">Next</a></li>
                      </ul>
                    </nav>
                  </div>

                  <div className="container border rounded mt-5">
                    {show && (
                      <DeleteConfirm
                        handleConfirmDelete={handleConfirmDelete}
                        itemName={
                          filteredSurveys.find(
                            (survey) => survey.id === selectedSurveyId
                          )?.member
                        }
                        handleClose={handleClose}
                        handleShow={handleShow}
                        show={show}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AssessmentInfo;
