import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../../../apiConfig";
import DataTable from "react-data-table-component";

import TableLoader from "../../common/TableLoader";
import { Link } from "react-router-dom";
import Spinner from "../../common/Spinner";
import DeleteConfirm from "../../common/DeleteConfirm";
import useTokenExpiration from "../../../useTokenExpiration";

function Teams() {
  const [teams, setTeams] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [selectedTeamId, setSelectedTeamId] = useState(null) 
  const { errorMessage, handleTokenExpiry } = useTokenExpiration();



  const [show, setShow] = useState(false);

//   const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false)



  const handleConfirmDelete = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/accounts/delete_team/${selectedTeamId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      if(response.status === 401) {
        handleTokenExpiry()
        return
      }

      if (response.ok) {
        getTeams();
      } else {
        console.log("server error");
      }
    } catch (error) {
      console.log("An error occured while deleting team", error);
    }
    setTimeout(() => {
      setShow(false);
    }, 1000);
  };


  const handleDelete = (teamId) => {
    setSelectedTeamId(teamId);
  };
 

  function convertToCSV(data) {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((row) => Object.values(row).join(","));
    return [header, ...rows].join("\n");
  }

  const getTeams = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/api/accounts/get_teams/`, {
        method: "GET",
        headers: {
           'Authorization': `Bearer ${localStorage.getItem("access_token")}`
        },
      });

      if(response.status === 401) {
        handleTokenExpiry()
        return
      }
      const data = await response.json();
      Array.isArray(setTeams(data) || [])
     
      console.log("Teams", data);
    } catch (error) {
      console.log("an error occurred while fetching teams");
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredTeams = teams.filter((team) => {
    return (
      team.team_name.toLowerCase().includes(searchText.toLowerCase()) ||
      team.user.toLowerCase().includes(searchText.toLowerCase())
    );
  });

  const columns = [
    {
      name: "Team name",
      selector: (row) => <Link to={`/main/team_members`}>{row.team_name}</Link>,
      sortable: true,
      className: "bold-header",
    },
    {
      name: "Team owner",
      selector: (row) => `${row.user} `,
      sortable: true,
      className: "bold-header",
    },

    {
        cell: (row) => (
          <div className="dropdown">
            <button
              className="btn  btn-sm text-xs"
              type="button"
              id={`dropdownMenuButton-${row.id}`}
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="fa fa-ellipsis-v"></span>
            </button>
            <div
              className="dropdown-menu text-xs"
              aria-labelledby={`dropdownMenuButton-${row.id}`}
            >
             
                <>
               
                  <a
                    className="text-white"
                    href={`/main/update_team/${row.id}`}
                  >
                    <button className="dropdown-item">Edit</button>
                  </a>
  
                  <button
                    className="dropdown-item"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleDelete(row.id);
                      handleShow();
                    }}
                  >
                    Delete
                  </button>
                </>
          
            </div>
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        minWidth: "60px", // Set a minimum width for the dropdown button
      },
  ];

  useEffect(() => {
 

    const timer = setTimeout(() => {
        getTeams();
        setIsLoading(false)
    }, 1000);

    return () => clearTimeout(timer)
    
  }, []);

  return (
    <>
    {isLoading ?(
        <Spinner />
    ) : (
        <div className="content-wrapper" style={{minHeight: "90vh"}}>
        <section className="content-header ">
          <div className="container-fluid ">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h5>Teams </h5>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/main/dashboard">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Teams</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid ">
            <div className="row">
              <div className="col-12 ">
                <div className="card">
                  <div className="card-body ">
                    <div className="container   mt-5">
                      <div className="row ">
                        <div className="col-sm-6">
                          <h3 className="card-title text-bold">Teams</h3>
                        </div>
                        <div className="col-sm-6 text-right">
  
                        <Link
                        to={`/main/add_team`}
                            type="button"
                            className="btn btn-outline-primary btn-xs"
                            style={{ float: "right", marginRight: "10px" }}
                          >
                            Add team 
                          </Link>
  
  
                          <button
                            type="button"
                            className="btn btn-outline-primary btn-xs"
                            style={{ float: "right", marginRight: "10px" }}
                            onClick={() => {
                              const csv = teams.map((team) => ({
                                Team: team.team_name,
                                Team_owner: `${team.user}`,
                              }));
                              const csvData = convertToCSV(csv);
                              const blob = new Blob([csvData], {
                                type: "text/csv",
                              });
                              const url = window.URL.createObjectURL(blob);
                              const a = document.createElement("a");
                              a.setAttribute("href", url);
                              a.setAttribute("download", "users.csv");
                              a.click();
                            }}
                          >
                            Export to CSV
                          </button>
  
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="Search..."
                            value={searchText}
                            onChange={handleSearch}
                            style={{
                              width: "200px",
                              float: "right",
                              marginRight: "10px",
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    {errorMessage && (
                           <div className=" container mt-2 bg-danger">
                           <p className="p-2 ">{errorMessage}</p>
                           </div>
                      )}
  
                    <div className="container border rounded mt-5">
                      <div>
                        {loading}
                        <DataTable
                          columns={columns}
                          data={filteredTeams}
                          fixedHeader
                          responsive={true}
                          selectableRows
                          progressPending={loading}
                          progressComponent={<TableLoader />}
                          onTableUpdate={() => setLoading(false)} // Reset loading state when table updates
                          striped // Enable row striping
                          highlightOnHover // Highlight row
                          pagination // Paginate
                        />
                      </div>

                      {show && (
                        <DeleteConfirm
                          handleConfirmDelete={handleConfirmDelete}
                          itemName={
                            teams.find(
                              (team) => team.id === selectedTeamId
                            )?.team_name
                          }
                          handleClose={handleClose}
                          handleShow={handleShow}
                          show={show}
                        />
                      )}


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )}
   
    </>
  );
}

export default Teams;
