import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../../../apiConfig";
import { useNavigate, useParams } from "react-router-dom";
import useTokenExpiration from "../../../useTokenExpiration";

const UpdateRole = () => {
  const navigate = useNavigate();
  const params = useParams()
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([])
  const [formData, setFormData] = useState({
    role_name: "",
    user: "", 
  });


  const { errorMessage, handleTokenExpiry } = useTokenExpiration();

  



  const getRoles = async () => {
  
    try {
      const response = await fetch(`${API_BASE_URL}/api/accounts/get_user_roles/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      const data = await response.json();
      setRoles(data);
      console.log("Roles", data);
    } catch (error) {
      console.log("an error occurred while fetching roles");
    } finally {
     
    }
  };



  const getRole = async () => {
  
    try {
      const response = await fetch(`${API_BASE_URL}/api/accounts/get_role/${params.id}/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      const data = await response.json();
      setFormData(data);
      console.log("Roles", data);
    } catch (error) {
      console.log("an error occurred while fetching roles");
    } finally {
     
    }
  };

  const getUsers = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/accounts/users/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setUsers(data);
        console.log("Users", data);
      } else {
        console.log("Failed to fetch users");
      }
    } catch (error) {
      console.error("An error occurred while fetching users", error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };



  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${API_BASE_URL}/api/accounts/update_role/${params.id}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log("Role updated successfully");
        navigate("/main/roles");
      } else {
        console.log("Failed to create role");
      }
    } catch (error) {
      console.error("An error occurred while creating role", error);
    }
  };


  useEffect(() => {
    getRole()
  }, [params.id])


  useEffect(() => {
    getUsers();
    getRoles()
  }, []);

  return (
    <div className="content-wrapper" style={{ minHeight: "90vh" }}>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h5>Roles</h5>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/main/dashboard">Home</a>
                </li>
                <li className="breadcrumb-item active">Update Role</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="container mt-5">
                    <div className="row">
                      <div className="col-sm-6">
                        <h3 className="card-title text-bold">Add Role</h3>
                      </div>
                      <div className="col-sm-6 text-right"></div>
                    </div>
                  </div>

                  <div className="container border rounded mt-5">
                    <form onSubmit={handleSubmit} style={{ margin: "0 10px" }}>
                      <div className="mt-4">
                      <div>
                          <h6 className="text-bold">Role name</h6>
                          <select
                            type="text"
                            className="form-control mb-2 rounded"
                            name="role_name"
                            value={formData.role_name}
                            onChange={handleChange}
                            required
                          >
                            <option value="">Select Role</option>
                            <option value="Team Member">Team Member</option>
                            <option value="Admin">Admin</option>
                            <option value="Team Lead">Team Lead</option>
                            <option value="Director">Director</option>
                          </select>
                        </div>

                        <div>
                          <h6 className="text-bold">Users</h6>
                          <select
                            name="user"
                          
                            value={formData.user}
                            onChange={handleChange}
                            className="form-control mb-2 rounded"
                            required
                          >
                            {users.map((user) => (
                              <option key={user.id} value={user.id}>
                                {user.username}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="card-footer">
                        <button
                          type="submit"
                          className="btn app__bgColor text-white  text-bold btn-sm"
                        >
                          Save Details
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-secondary btn-sm"
                          style={{ float: "right" }}
                          onClick={() => {
                            navigate("/main/roles");
                          }}
                        >
                          Close Form
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UpdateRole;
