import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../../../apiConfig";
import DataTable from "react-data-table-component";

import TableLoader from "../../common/TableLoader";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "../../common/Spinner";
import DeleteConfirm from "../../common/DeleteConfirm";
import useTokenExpiration from "../../../useTokenExpiration";

function Transactions() {
  const [transactions, setTransactions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
 

  const [selectedTransactionId, setSelectedTRansactionId] = useState(null) 
  const { errorMessage, handleTokenExpiry } = useTokenExpiration();



  const [show, setShow] = useState(false);

//   const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false)



  const handleConfirmDelete = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/payment/payments/delete/${selectedTransactionId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      if(response.status === 401) {
        handleTokenExpiry()
        return
      }

      if (response.ok) {
        getTransactions();
      } else {
        console.log("server error");
        
      }
    } catch (error) {
      console.log("An error occured while deleting team", error);
    }
    setTimeout(() => {
      setShow(false);
    }, 1000);
  };


  const handleDelete = (transactionId) => {
    setSelectedTRansactionId(transactionId);
  };
 

  function convertToCSV(data) {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((row) => Object.values(row).join(","));
    return [header, ...rows].join("\n");
  }

  const getTransactions = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/api/payment/payments/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });


      if(response.status === 401) {
        handleTokenExpiry()
        return
      }



      const data = await response.json();
      setTransactions(Array.isArray(data) ? data : []);
      console.log("Transactions", data);
    } catch (error) {
      console.log("an error occurred while fetching teams");
  
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };




  const filteredTransactions = transactions.filter((transaction) => {
    return (
      transaction.payment_status.toLowerCase().includes(searchText.toLowerCase()) 
    );
  });

  const columns = [

    {
      name: "Date of payment",
      selector: (row) => `${new Date(row.created_at).toLocaleDateString()}`,
      sortable: true,
      className: "bold-header",
    },

    {
      name: "Assessment ID",
      selector: (row) => `${row.assessment_id}`,
      sortable: true,
      className: "bold-header",
    },
    {
      name: "Member",
      selector: (row) => `${row.user }`,
      sortable: true,
      className: "bold-header",
    },

    {
      name: "Email Address",
      selector: (row) => `${row.email} `,
      sortable: true,
      className: "bold-header",
    },
    {
      name: "Amount",
      selector: (row) => `${row.amount} `,
      sortable: true,
      className: "bold-header",
    },

 



    {
        name: "Payment status",
        selector: (row) => <span className={` badge  ${row.payment_status === 'unpaid' ? "badge-warning" : " badge-success"}`}>{row.payment_status} </span>,
        sortable: true,
        className: "bold-header",
      },



    {
        cell: (row) => (
          <div className="dropdown">
            <button
              className="btn  btn-sm text-xs"
              type="button"
              id={`dropdownMenuButton-${row.id}`}
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="fa fa-ellipsis-v"></span>
            </button>
            <div
              className="dropdown-menu text-xs"
              aria-labelledby={`dropdownMenuButton-${row.id}`}
            >
             
                <>
               
  
                  <button
                    className="dropdown-item"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleDelete(row.id);
                      handleShow();
                    }}
                  >
                    Delete
                  </button>
                </>
          
            </div>
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        minWidth: "60px", // Set a minimum width for the dropdown button
      },
  ];

  useEffect(() => {
 

    const timer = setTimeout(() => {
        getTransactions();
        setIsLoading(false)
    }, 1000);

    return () => clearTimeout(timer)
    
  }, []);

  return (
    <>
    {isLoading ?(
        <Spinner />
    ) : (
        <div className="content-wrapper" style={{minHeight: "90vh"}}>
        <section className="content-header ">
          <div className="container-fluid ">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h5>Transactions </h5>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/main/dashboard">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Transactions</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid ">
            <div className="row">
              <div className="col-12 ">
                <div className="card">
                  <div className="card-body ">
                    <div className="container   mt-5">
                      <div className="row ">
                        <div className="col-sm-6">
                          <h3 className="card-title text-bold">Transactions</h3>
                        </div>

                        
                        <div className="col-sm-6 text-right">
  
                      
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="Search..."
                            value={searchText}
                            onChange={handleSearch}
                            style={{
                              width: "200px",
                              float: "right",
                              marginRight: "10px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                      {errorMessage && (
                           <div className=" container mt-2 bg-danger">
                           <p className="p-2 ">{errorMessage}</p>
                           </div>
                      )}
                   
                    
  
                    <div className="container border rounded mt-5">
                    
                      <div>
                        {loading}
                        <DataTable
                          columns={columns}
                          data={filteredTransactions}
                          fixedHeader
                          responsive={true}
                          selectableRows
                          progressPending={loading}
                          progressComponent={<TableLoader />}
                          onTableUpdate={() => setLoading(false)} // Reset loading state when table updates
                          striped // Enable row striping
                          highlightOnHover // Highlight row
                          pagination // Paginate
                        />
                      </div>

                      {show && (
                        <DeleteConfirm
                          handleConfirmDelete={handleConfirmDelete}
                          itemName={
                            transactions.find(
                              (transaction) => transaction.id === selectedTransactionId
                            )?.name
                          }
                          handleClose={handleClose}
                          handleShow={handleShow}
                          show={show}
                        />
                      )}


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )}
   
    </>
  );
}

export default Transactions;
