import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../../../apiConfig";
import { useNavigate, useParams } from "react-router-dom";
import * as XLSX from "xlsx";

const UploadMembers = () => {
  const navigate = useNavigate();
  const [teams, setTeams] = useState([]);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [assessmentId, setAssessmentId] = useState(null);

  const assessemnt_id = localStorage.getItem("assessemnt_id")
  const params = useParams()



  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleAssessmentIdChange = (e) => {
    setAssessmentId(e.target.value);
  };


  const handleFileUpload = async (e) => {
    e.preventDefault();

    if (!file) {
      alert("Please select a file first");
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("assessment_id", localStorage.getItem("assessment_id"));

    try {
      const response = await fetch(`${API_BASE_URL}/api/accounts/upload_team_members/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: formData,
      });

      if (response.ok) {
        alert("You uploaded the sheet successfully! Members of your team will be receiving an email to start the assessment. Please check your inbox or your junk folder!");
        navigate(`/main/payment/${params.id}`);
      } else {
        const data = await response.json();
        alert(`Error: ${data.error}`);
      }
    } catch (error) {
      console.log("Error uploading file:", error);
    } finally {
      setLoading(false);
    }
  };

const generateExel = () => {
  const wb = XLSX.utils.book_new();
  const instructions = [
    ["Please fill the form correctly."],
    ["Ensure that each team member has a unique email address."],
    [""], 

   
  ];

  const headers = [["first_name", "last_name", "email", "team"]];
  const data = [...headers];
  const ws = XLSX.utils.aoa_to_sheet(data);
  XLSX.utils.book_append_sheet(wb, ws, "Team Members");
  XLSX.writeFile(wb, "team_members.xlsx");
}
  

  return (
    <div className="content-wrapper" style={{ minHeight: "90vh" }}>
      <section className="content-header ">
        <div className="container-fluid ">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h5>Teams Members</h5>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/main/dashboard">Home</a>
                </li>
                <li className="breadcrumb-item active">Upload Team Member</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-12 ">
              <div className="card">
                <div className="card-body ">
                  <div className="container mt-5">
                    <div className="row ">
                      <div className="col-sm-6">
                        <h3 className="card-title text-bold">Upload Team Members</h3>
                      </div>
                      <div className="col-sm-6 text-right">
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-xs"
                        style={{ float: "right", marginRight: "10px" }}
                        onClick={generateExel}
                      >
                        Download Excel Sheet
                      </button>
  
                      </div>
                    </div>
                    <p style={{fontSize: "14px"}} className=" mt-4">Download the template and enter names and emails of your team member in the designated columns.</p>
                  </div>

                  <div className="container border rounded mt-5">
                    <div>
                      <form onSubmit={handleFileUpload} style={{ margin: "0 10px" }}>
                        <div className="mt-4">
                          <div>
                            <h6 className="text-bold">Upload Excel File</h6>
                            <input
                              type="file"
                              name="file"
                              className="form-control mb-2 rounded"
                              accept=".xlsx, .xls"
                              onChange={handleFileChange}
                            />
                          </div>
                        </div>

                        <div className="card-footer">
                          <button
                            type="submit"
                            className="btn app__bgColor text-white text-bold btn-sm"
                            disabled={loading}
                          >
                            {loading ? "Uploading..." : "Upload Members"}
                          </button>
                          {loading  && <p>It may take a while,  while uploading and sending emails, Please wait! </p>}
                          
                          <button
                            type="button"
                            className="btn btn-outline-secondary btn-sm"
                            style={{ float: "right" }}
                            onClick={() => {
                              navigate("/main/team_members");
                            }}
                          >
                            Close Form
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UploadMembers;
