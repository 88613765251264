import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../../../apiConfig";
import { useNavigate, useParams } from "react-router-dom";

const CreateSurvey = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);

  const [formData, setFormData] = useState({});
  const [assessmentStatus, setAssessmentStatus] = useState("")


  const params = useParams()
  

  console.log(params)

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/accounts/users/`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        const data = await response.json();
        const loggedInUser = localStorage.getItem("userId");
        const filteredUsers = data.filter(
          (user) => user.id !== parseInt(loggedInUser)
        );
        setUsers(filteredUsers);
      } catch (error) {
        console.error("An error occurred while fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const handleChange = (event, userId, attribute) => {
    const { value } = event.target;
    setFormData({
      ...formData,
      [userId]: {
        ...formData[userId],
        [attribute]: value,
      },
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

   

    const surveyData = users.map((user) => ({
      assessment_id: params.id,
      member: user.id,
      competence: formData[user.id]?.competence || 5,
      credibility: formData[user.id]?.credibility || 5,
      clarity: formData[user.id]?.clarity || 5,
      connectivity: formData[user.id]?.connectivity || 5,
    }));

    try {
      const response = await fetch(`${API_BASE_URL}/api/survey/take_survey/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify(surveyData),
      });

      if (response.ok) {
        console.log("Surveys submitted successfully");
        navigate("/main/assessments");
        localStorage.setItem("assessmentStatus", "completed" )
      } else {
        const errorData = await response.json();
        console.error("Error response from server:", errorData);
      }
    } catch (error) {
      console.error("An error occurred while submitting data:", error);
    }
  };

  const LikertScale = ({ userId, attribute, value }) => (
    <div className="mb-4 w-full">
      <div className="d-flex align-items-center justify-content-between">
        <label htmlFor={`${userId}-${attribute}`} className="form-label">
          Bad
        </label>
        <label htmlFor={`${userId}-${attribute}`} className="form-label float-right">
          Excellent
        </label>
      </div>
      <div className="relative">
        <div className="range w-100">
          <input
            type="range"
            style={{ height: "2px" }}
            className="form-range w-full"
            min="0"
            max="10"
            step="1"
            name={`${userId}-${attribute}`}
            value={value || 5}
            onChange={(e) => handleChange(e, userId, attribute)}
            id={`${userId}-${attribute}`}
          />
          <div
            className="absolute left-0 top-0 transform -translate-y-8 translate-x-1/2 text-xs rounded px-2"
            style={{ left: `${(value / 10) * 100}%` }}
          >
            {value || 5}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="content-wrapper" style={{ minHeight: "90vh" }}>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h5>Take Assessment</h5>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/main/dashboard">Home</a>
                </li>
                <li className="breadcrumb-item active">Take Assessement</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="container mt-5">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="border rounded w-100 p-4 d-flex flex-column shadow-lg gap-3">
                          <h3 style={{ color: "#1b1553" }} className="card-title text-bold my-2">
                            Assessment Instructions:
                          </h3>
                          <ul style={{ fontSize: "14px", gap: "5px", listStyle: 'none' }}>
                            <li className="mb-2">a) Evaluate Interactions: Based on your interactions with the individual, use the sliding scale to evaluate them accordingly (1-10, 10 being the highest).</li>
                            <li className="mb-2">b) Quick Responses: Answer within approximately 5 seconds. Studies show that overthinking can lead to second-guessing. This is a heuristic assessment.</li>
                            <li className="mb-2">c) Next Member: Once you are done, proceed to assess the next member.</li>
                            <li className="mb-2">d) Optimal Conditions: If you are rushed or not in the best frame of mind, consider choosing another time to complete the assessment. If you experience fatigue or get distracted, take a break and return later.</li>
                            <li style={{listStyle: "none"}} className="mb-2">The 4Cs are: Competence, Credibility, Clarity (of communication) & Connectivity</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div style={{ overflowX: "auto" }} className="container rounded mt-5 shadow-lg bg-white  rounded">
                    <form onSubmit={handleSubmit} style={{ margin: "0 10px" }}>
                      <div className="mb-4 w-full">
                        {/* <label htmlFor="assessmentId" className="form-label" style={{ color: "#1b1553" }}>
                          Assessment ID
                        </label>
                        <input
                          type="text"
                          id="assessmentId"
                          className="form-control"
                          value={assessmentId}
                          onChange={(e) => setAssessmentId(e.target.value)}
                          required
                        /> */}
                      </div>

                      <table className="table table-bordered w-100">
                        <thead>
                          <tr>
                            <th style={{ color: "#1b1553" }}>Member</th>
                            <th style={{ color: "#1b1553" }}>Competence</th>
                            <th style={{ color: "#1b1553" }}>Credibility</th>
                            <th style={{ color: "#1b1553" }}>Clarity</th>
                            <th style={{ color: "#1b1553" }}>Connectivity</th>
                          </tr>
                          <tr>
                            <td></td>
                            <td>How good are they at what they do? (0 - 10) 10 is the highest</td>
                            <td>How much do you trust them as a person? (0 - 10) 10 is the highest</td>
                            <td>How well do they communicate about their area of expertise? (0 - 10) 10 is the highest</td>
                            <td>How well connected are they? (0 - 10) 10 is the highest</td>
                          </tr>
                        </thead>
                        <tbody>
                          {users.map((user) => (
                            <tr key={user.id}>
                              <td>{user.first_name ? user.first_name : user.username} {user.last_name}</td>
                              <td>
                                <LikertScale
                                  userId={user.id}
                                  attribute="competence"
                                  value={formData[user.id]?.competence}
                                />
                              </td>
                              <td>
                                <LikertScale
                                  userId={user.id}
                                  attribute="credibility"
                                  value={formData[user.id]?.credibility}
                                />
                              </td>
                              <td>
                                <LikertScale
                                  userId={user.id}
                                  attribute="clarity"
                                  value={formData[user.id]?.clarity}
                                />
                              </td>
                              <td>
                                <LikertScale
                                  userId={user.id}
                                  attribute="connectivity"
                                  value={formData[user.id]?.connectivity}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="card-footer">
                        <button type="submit" className="btn text-bold text-white app__bgColor btn-sm">
                          Submit
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-secondary btn-sm"
                          style={{ float: "right" }}
                          onClick={() => {
                            window.location.href = "/main/assessments";
                          }}
                        >
                          Close Form
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CreateSurvey;
