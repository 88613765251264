import React, { useEffect, useState } from "react";
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../apiConfig";

function Signin() {
  const [errorMessage, setErrorMessage] = useState("");
  
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordShow = () => {
    setShowPassword(!showPassword);
  };

  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDataSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);

      const response = await fetch(`${API_BASE_URL}/api/accounts/signin/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data)

       
        localStorage.setItem("role", data.role)
        localStorage.setItem('username', data.username)
        localStorage.setItem('userId', data.user_id)
        localStorage.setItem("access_token", data.access_token)

        setTimeout(() => {
          if(localStorage.getItem("role") === 'Admin') {
            navigate("/main/teams")
          } else {
            navigate("/main/dashboard");
          }
         
          window.location.reload()
          setLoading(false);
        }, 4000);
      } else {
        setErrorMessage("username or password is incorrect");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setErrorMessage("");
    }, 5000);
  }, [errorMessage]);

  return (
    <div className="d-flex align-items-center justify-content-center min-vh-100">
      <div className="login-container container mx-auto">
        <div className="d-flex align-items-center justify-content-center flex-column bg-white custom-card w-100 w-md-50 rounded p-4 shadow">
          <h5 className="text-center font-weight-bold my-4">
            Sign in to start your session
          </h5>
          <div className="w-100">
            <form onSubmit={handleDataSubmit} className="d-flex flex-column">
              <div>
                <label style={{ fontSize: "14px", color: "gray" }}>
                  Username
                </label>
                <input
                  type="text"
                  placeholder="Username"
                  className="form-control mb-2 rounded"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  required
                />
              </div>




              <div>
              <label style={{ fontSize: "14px", color: "gray" }}>
                  Enter password
                </label>

              <div className="input-group mb-2">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  className="form-control border-right-0"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
                <div className="input-group-append">
                  <span
                    className="input-group-text bg-white border-left-0"
                    onClick={handlePasswordShow}
                  >
                    {showPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                  </span>
                </div>
              </div>

              </div>

             
             
              <button
                type="submit"
                className="btn app__bgColor  text-white font-weight-bold rounded my-4"
                
              >
                {loading ? (
                  <>
                    <span
                      className="spinner-border app__bgColor spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="ml-2">Signing in...</span>
                  </>
                ) : (
                  "Sign in"
                )}
              </button>
            </form>
            {errorMessage && (
              <p className="alert alert-danger">{errorMessage}</p>
            )}
          </div>

          {/* <Link to={`/signup`} className=" text-primary" style={{fontSize: "14px", fontWeight: "500" }}>Don't have an account? <span>create account</span></Link> */}
        </div>
      </div>
    </div>
  );
}

export default Signin;
