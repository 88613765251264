import React, { useState } from "react";
import AssessmentModal from "./AssessmentModal";
import { loadStripe } from "@stripe/stripe-js";

import OrderChart from "../DashboardCharts/OrdersChart";
import CityChart from "../DashboardCharts/CityChart";
import CompanyAnalysisChart from "../DashboardCharts/CompanyAnalysisChart";
import CountryAnalysis from "../DashboardCharts/CountryAnalysis";
import CompaniesTable from "../DashboardCharts/CompaniesTable";
import RecentTransactions from "../Payment/RecentTransactions";
import RecentAssessments from "../Survey/RecentAssesments";

const stripePromise = loadStripe(
  "pk_test_51PVTjEAOYFEAvCd9WQCXJANOS9cyzL8aj1KAkAh1dvin1UawyhorEGDOkLXlTzQXbmcDlrNoPkDN3gWUTysWZXPZ00la2b9JBa"
);

const Dashboard = () => {
  const [sessionId, setSessionId] = useState(null);

  const fetchCheckoutSession = async () => {
    try {
      const response = await fetch(
        "http://localhost:8000/api/payment/create_checkout_session/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          body: JSON.stringify({ amount: 50.0 }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const session = await response.json();
      setSessionId(session.session_id);
      handlePayment(session.session_id);
    } catch (error) {
      console.error("Error fetching checkout session:", error);
    }
  };

  const handlePayment = async (sessionId) => {
    if (!sessionId) {
      console.error("Session ID not available.");
      return;
    }

    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      sessionId: sessionId,
    });

    if (error) {
      console.error("Error:", error);
    }
  };
  return (
    <div className="content-wrapper" style={{ minHeight: "90vh" }}>
    <section className="content-header ">
    <div className="container-fluid ">
      <div className="row mb-2">
        <div className="col-sm-6">
          <h5>Leader Board </h5>
        </div>
        <div className="col-sm-6">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item">
              <a href="/main/dashboard">Home</a>
            </li>
            <li className="breadcrumb-item active">Leader Board</li>
          </ol>
        </div>
      </div>
    </div>
  </section>
    <section className="content">
      <div className="container-fluid">
        {/* <div className="row">
          <div className="col-md-6">
            <CountryAnalysis />
            <OrderChart />
          </div>

          <div className="col-md-6">
            
            <CityChart />
            <CompanyAnalysisChart />
          </div>
        </div> */}
        <AssessmentModal  />

        <RecentAssessments />
        
      </div>
    </section>
    </div>

   
  );
};

export default Dashboard;
