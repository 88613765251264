import { useState } from "react"
import { useNavigate } from "react-router-dom"

const useTokenExpiration = () => {
    const [errorMessage, setErrorMessage] = useState("")

    const navigate = useNavigate()

    const handleTokenExpiry = () => {
        setErrorMessage("Your session has expired. Please login again.");
        setTimeout(() => {
          localStorage.removeItem("access_token");
          navigate("/");
        }, 3000);
      }

      return {errorMessage, handleTokenExpiry} 
}


export default useTokenExpiration