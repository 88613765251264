import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../../../apiConfig";

import { FcBusinessman } from "react-icons/fc";
import Spinner from "../../common/Spinner";
import { Link } from "react-router-dom";

const UserProfile = () => {
  const [user, setUser] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const getUserProfile = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/accounts/user_profile/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      const data = await response.json();

      setUser(data);
      console.log("logged in user", data);
    } catch (error) {
      console.log("an error occurred while fetching users");
    } finally {
    }
  };

  const getUserInfoProfile = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/accounts/user_info__user/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      const data = await response.json();

      setUserInfo(data);
      console.log("logged in user info", data);
    } catch (error) {
      console.log("an error occurred while fetching users");
    } finally {
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getUserProfile();
      getUserInfoProfile();
      setIsLoading(false)
    }, 1000);

    return () => clearTimeout(timer);
  }, [user.id]);



  return (
    <>
    {isLoading ? (
      <Spinner />
    ): (
      <div className="content-wrapper" style={{ minHeight: "90vh" }}>
      <section className="content-header ">
        <div className="container-fluid ">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h5>user profile </h5>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/main/dashboard">Home</a>
                </li>
                <li className="breadcrumb-item active">user profile</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-12 ">
              <div className="card">
                <div className="card-body ">
                  <div className="container   mt-2">
                    <div className="row ">
                      <div className="col-sm-6">
                        <h3 className="card-title text-bold">user profile</h3>
                      </div>
                    </div>
                  </div>

                  <div className="container border rounded mt-1 shadow-lg bg-white p-3">
                    <div style={{position: "relative"}} className="row d-flex ">
                      <div className="col-sm-12">
                        <div
                          style={{ width: "120px", height: "120px" }}
                          className=" p-6 rounded-circle border d-flex align-items-center justify-content-center "
                        >
                          <FcBusinessman size={80} />
                        </div>
                      </div>

                      <Link to={`/main/update_profile`} style={{position: "absolute", top: "10px", right: "10px"}}  className=" float-right btn btn-outline-primary btn-xs">Update profile</Link>
                    </div>

                    <div className="">
                      <div className="row">
                        <div className="col-md-6">
                          <div>
                            <label style={{ fontSize: "12px" }}>Username</label>

                            <p
                              style={{ borderColor: "	#989898" }}
                              className=" text-md border  rounded p-2"
                            >
                              {user?.username}
                            </p>
                          </div>

                          <div>
                            <label style={{ fontSize: "12px" }}>
                              Last name
                            </label>
                            <p
                              style={{ borderColor: "	#989898" }}
                              className=" text-md border  rounded p-2"
                            >
                              {user?.last_name}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div>
                            <label style={{ fontSize: "14px" }}>
                              First name
                            </label>
                            <p
                              style={{ borderColor: "	#989898" }}
                              className=" text-md border  rounded p-2"
                            >
                              {user?.first_name}
                            </p>
                          </div>

                          <div>
                            <label style={{ fontSize: "14px" }}>Email</label>
                            <p
                              style={{ borderColor: "	#989898" }}
                              className=" text-md border  rounded p-2"
                            >
                              {user?.email}
                            </p>
                          </div>
                        </div>
                      </div>

                      {/* Row 2 */}

                      <div className="row">
                        <div className="col-md-6">
                          <div>
                            <label style={{ fontSize: "14px" }}>Country</label>

                            <div>
                              <p
                                style={{ borderColor: "	#989898" }}
                                className=" text-md border  rounded p-2"
                              >
                                {userInfo?.country}
                              </p>
                            </div>
                          </div>

                          <div className=" my-2">
                            <label style={{ fontSize: "14px" }}>
                            Team Name (optional)
                            </label>
                            <p
                              style={{ borderColor: "	#989898" }}
                              className=" text-md border  rounded p-2"
                            >
                              {userInfo?.chaptername}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div>
                            <label style={{ fontSize: "14px" }}>City</label>
                            <p
                              style={{ borderColor: "	#989898" }}
                              className=" text-md border  rounded p-2"
                            >
                              {userInfo?.city}
                            </p>
                          </div>

                          <div>
                            <label style={{ fontSize: "14px" }}>
                              Team Lead(optional)
                            </label>
                            <p
                              style={{ borderColor: "	#989898" }}
                              className=" text-md border  rounded p-2"
                            >
                              {userInfo?.teamlead}
                            </p>
                          </div>
                        </div>
                      </div>

                      {/* Row 3 */}

                      <div className="row">
                        <div className="col-md-6">
                          <div>
                            <label style={{ fontSize: "14px" }}>
                              Phone Number
                            </label>
                            <p
                              style={{ borderColor: "	#989898" }}
                              className=" text-md border  rounded p-2"
                            >
                              {userInfo?.phone_number}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div>
                            <label style={{ fontSize: "14px" }}>
                              Email address
                            </label>
                            <p
                              style={{ borderColor: "	#989898" }}
                              className=" text-md border  rounded p-2"
                            >
                              {user?.email}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    )}
   
    </>
  );
};

export default UserProfile;
