import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../../../apiConfig";
import { FcBusinessman } from "react-icons/fc";
import { useParams, useNavigate } from "react-router-dom";
import Spinner from "../../common/Spinner";

const CreateProfile = () => {
    const [userId, setUserId] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
  const [formData, setFormData] = useState({
    user: "",
    country: "",
    city: "",
    chaptername: "",
    teamlead: "",
    phone_number: "",
  });

  const params = useParams();
  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const getUserInfoProfile = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/accounts/user_info__user/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setFormData(data);
        setUserId(data.user)
      } else {
        console.log("Failed to fetch user info");
      }
    } catch (error) {
      console.log("An error occurred while fetching user info:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/accounts/create_user_info/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        console.log("created successfully");
        navigate("/main/user_profile");
      } else {
        console.log("An error occurred while submitting data:", await response.text());
      }
    } catch (error) {
      console.log("An error occurred while submitting data:", error);
    }
  };

  useEffect(() => {
    getUserInfoProfile();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
        setIsLoading(false)
    }, 1000);

    return () => clearTimeout(timer)
  })

  return (
    <>
    {isLoading ? (
        <Spinner />
    ) : (
        <div className="content-wrapper" style={{ minHeight: "90vh" }}>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h5>User Profile</h5>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/main/dashboard">Home</a>
                  </li>
                  <li className="breadcrumb-item active">User Profile</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="container mt-5">
                      <div className="row">
                        <div className="col-sm-6">
                          <h3 className="card-title text-bold">User Profile</h3>
                        </div>
                      </div>
                    </div>
  
                    <div style={{position: "relative"}} className="container border rounded mt-1 shadow-lg bg-white p-3">
                      <div className="row">
                        <div className="col-sm-12">
                          <div
                            style={{ width: "120px", height: "120px" }}
                            className="p-6 rounded-circle border d-flex align-items-center justify-content-center"
                          >
                            <FcBusinessman size={80} />
                          </div>
                        </div>
                      </div>
                      <form onSubmit={handleSubmit}>
                        <div className="">
  
                        <div>
                                <label style={{ fontSize: "14px" }}>User</label>
                                <input
                                  type="text"
                                  placeholder="User"
                                  className="form-control mb-2 rounded border"
                                  style={{ borderColor: "#989898" }}
                                  value={formData.user}
                                  name="user"
                                  onChange={handleChange}
                                />
                              </div>
  
                          
                          <div className="row">
                            <div className="col-md-6">
                              <div>
                                <label style={{ fontSize: "14px" }}>Country</label>
                                <input
                                  type="text"
                                  placeholder="Country"
                                  className="form-control mb-2 rounded border"
                                  style={{ borderColor: "#989898" }}
                                  value={formData.country}
                                  name="country"
                                  onChange={handleChange}
                                />
                              </div>
  
                              <div className="my-2">
                                <label style={{ fontSize: "14px" }}>
                                  Team Name (optional)
                                </label>
                                <input
                                  type="text"
                                  placeholder="Chapter Name"
                                  className="form-control mb-2 rounded border"
                                  name="chaptername"
                                  style={{ borderColor: "#989898" }}
                                  value={formData.chaptername}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
  
                            <div className="col-md-6">
                              <div>
                                <label style={{ fontSize: "14px" }}>City</label>
                                <input
                                  type="text"
                                  placeholder="City"
                                  className="form-control mb-2 rounded border"
                                  name="city"
                                  style={{ borderColor: "#989898" }}
                                  value={formData.city}
                                  onChange={handleChange}
                                />
                              </div>
  
                              <div>
                                <label style={{ fontSize: "14px" }}>
                                  Team Lead (optional)
                                </label>
                                <input
                                  type="text"
                                  placeholder="Team Lead"
                                  className="form-control rounded border"
                                  name="teamlead"
                                  style={{ borderColor: "#989898" }}
                                  value={formData.teamlead}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
  
                          <div className="row">
                            <div className="col-md-6">
                              <div>
                                <label style={{ fontSize: "14px" }}>
                                  Phone Number
                                </label>
                                <input
                                  type="text"
                                  placeholder="Phone Number"
                                  className="form-control mb-2 rounded border"
                                  name="phone_number"
                                  style={{ borderColor: "#989898" }}
                                  value={formData.phone_number}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className=" mt-3">
                            <button
                              type="submit"
                              className="btn  btn-xs px-3 py-1 text-white app__bgColor"
                              style={{position: "absolute", right: "10px", bottom: "20px"}}
                            >
                              Update
                            </button>
                          </div>
                          </div>
  
                          
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )}
    
    </>
  );
};

export default CreateProfile;
