import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import PaymentModal from "./PaymentModal";
import { API_BASE_URL } from "../../../apiConfig";
import { useParams } from "react-router-dom";
import useTokenExpiration from "../../../useTokenExpiration";


let stripePromise;

const Payment = () => {
  const [show, setShow] = useState(true);
  const [sessionId, setSessionId] = useState(null);
  const [btnLoading, setLoading] = useState(true);
  const [assessmentId, setAssessmentId] = useState(null)
  const [userCount, setUserCount] = useState(0);

  const { errorMessage, handleTokenExpiry } = useTokenExpiration();
  

  const params = useParams()
  
  console.log("ASSESSMENT ID", params.id)

  const handleClose = () => {
    setShow(false);
  };


  const getPublishableKey = async () => {
    try{
  
      const response = await fetch(`${API_BASE_URL}/app_config/app_configs/`,{
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
  

        if(response.status === 401) {
          handleTokenExpiry()
          return
        }
        const data = await response.json()
        console.log("STRIPE PK ", data[0].stripe_publishable_key)
        const stripePublishableKey = data[0]?.stripe_publishable_key
        stripePromise = loadStripe(stripePublishableKey)
  
        if(!response.ok) {
          throw new Error(`HTTP Error ! Status ${response.status}`)
        }
  
    } catch(error) {
      console.log("An error occured while fetching publishable key", error)
    }
  }




  const fetchUserCount = async (assessmentId) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/accounts/assessment/${assessmentId}/user_count/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      if(response.status === 401) {
        handleTokenExpiry()
        return
      }

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setUserCount(data.user_count);
    } catch (error) {
      console.error("Error fetching user count:", error);
    }
  };



  const fetchCheckoutSession = async () => {
    const amount = userCount * 50.0;
    setLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/payment/create_checkout_session/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          body: JSON.stringify({ 
            amount, 
            assessment_id: assessmentId
          }),
        }
      );

      if(response.status === 401) {
        handleTokenExpiry()
        return
      }

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const session = await response.json();
      setSessionId(session.session_id);
      handlePayment(session.session_id);
    } catch (error) {
      console.error("Error fetching checkout session:", error);
    } finally {
      setLoading(false);
    }
  };




  const handlePayment = async (sessionId) => {
    if (!sessionId) {
      console.error("Session ID not available.");
      return;
    }

    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      sessionId: sessionId,
    });

    if (error) {
      console.error("Error:", error);
    }
  };


    useEffect(() => {
    setAssessmentId(params.id)
    fetchUserCount(params.id)
  }, [params.id])


  useEffect(() => {
    getPublishableKey()
  }, [])


  return (
    <div className="content-wrapper" style={{ minHeight: "90vh" }}>
      <section className="content-header ">
        <div className="container-fluid ">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h5>Assessments </h5>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/main/dashboard">Home</a>
                </li>
                <li className="breadcrumb-item active">Assessments</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-12 ">
              <div className="card">
                <div className="card-body ">
                  <div className="container mt-5">
                    <div className="row ">
                      <div className="col-sm-6">
                        <h3 className="card-title text-bold">
                          Assessments Payment
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div className="container border rounded p-5 mt-5">
                    <p style={{ fontSize: "14px" }}>
                    To continue with your assessment, kindly complete the payment process.
                      </p>

                    {btnLoading ? (
                      <button
                        onClick={fetchCheckoutSession}
                        type="button"
                        className="btn app__bgColor text-white"
                      >
                        Make Payment
                      </button>
                    ) : (
                      <button  className="btn app__bgColor text-white" type="button" disabled>
                        <span
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span class="sr-only ">Loading...</span>
                        Processing...
                      </button>
                    )}

                     <div style={{display: "none"}}>
                            <h6 className=" text-bold">Assessment ID </h6>
                            <input
                              type="text"
                              className="form-control mb-2 rounded"
                              name="assessment_id"
                              value={assessmentId}
                              // onChange={handleChange}
                              readOnly
                            />
                          </div>
                  </div>

                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Payment;
