import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../../../apiConfig";
import DataTable from "react-data-table-component";

import TableLoader from "../../common/TableLoader";
import { Link, useParams } from "react-router-dom";
import Spinner from "../../common/Spinner";
import DeleteConfirm from "../../common/DeleteConfirm";
import useTokenExpiration from "../../../useTokenExpiration";

function Assessments() {
  const [assessments, setAssessments] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [hasPaid, setHasPaid] = useState(false);
  const [selectedSurveyId, setSelectedSurveyId] = useState(null);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [assessmentStatus, setAssessmentStatus] = useState("");
  const [membersCount, setMembersCount] = useState(null)

  const { errorMessage, handleTokenExpiry } = useTokenExpiration();


  //   const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const USERROLE = localStorage.getItem("role");
  const params = useParams()

  const handleConfirmDelete = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/survey/delete_assessment/${selectedSurveyId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );



      if(response.status === 401) {
        handleTokenExpiry()
        return
      }

      if (response.ok) {
        getAssessments();
      } else {
        console.log("Server error");
      }
    } catch (error) {
      console.log("An error occurred while deleting survey", error);
    }
    setTimeout(() => {
      setShow(false);
    }, 1000);
  };

  const handleDelete = (surveyId) => {
    setSelectedSurveyId(surveyId);
    handleShow();
  };

  function convertToCSV(data) {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((row) => Object.values(row).join(","));
    return [header, ...rows].join("\n");
  }

  const getMembersCountForAssessment = async (assessmentId) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/survey/team_members_count/${assessmentId}/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );



      if(response.status === 401) {
        handleTokenExpiry()
        return
      }

      if (!response.ok) {
        throw new Error(`Server error: status ${response.status}`);
      }

      const data = await response.json();
      console.log(data)
      return data.team_members_count;
    } catch (error) {
      console.log("Error fetching assessment members count", error);
      return 0;
    }
  };

  const getAssessments = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/survey/get_assessments/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );



      if(response.status === 401) {
        handleTokenExpiry()
        return
      }

      if (response.ok) {
        const data = await response.json();
        const paidAssessments = data.filter((paid) => paid.payment_status === 'paid')

        let assesmentsToShow
        if(USERROLE === 'Team Member') {
          assesmentsToShow = paidAssessments
        } else {
          assesmentsToShow = data;
        }
       
        console.log("Assessments ", paidAssessments)

        const assessmentsWithMembersCount = await Promise.all(
          assesmentsToShow.map(async (assessment) => {
            const membersCount = await getMembersCountForAssessment(assessment.id);
            return { ...assessment, membersCount };
          })
        );

        const hasTeamLeadPaid = assessmentsWithMembersCount.some(
          (assessment) => assessment.payment_status === "paid"
        );
        setHasPaid(hasTeamLeadPaid);
        setAssessments(assessmentsWithMembersCount);
      }
    } catch (error) {
      console.log("Error fetching assessments", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const isSurveyComplete = (surveys) => {
    return surveys.every((survey) => survey.status === "completed");
  };

  const getSurveyStatus = (surveys) => {
    const userId = localStorage.getItem("userId");
    if (surveys.length === 0) return "pending";
    const userSurvey = surveys.find((survey) => survey.assessor === parseInt(userId));
    return userSurvey ? userSurvey.status : "pending";
  };


  const filteredAssessments = Array.isArray(assessments)
    ? assessments.filter((assessment) => {
        return (
          assessment.assessment_name
            .toString()
            .toLowerCase()
            .includes(searchText.toLowerCase()) || ""
        );
      })
    : [];

  const columns = [
    {
      name: "Assessment Name",
      selector: (row) => `${row.assessment_name}`,
      sortable: true,
      className: "bold-header",
    },

  

    {
      name: "Assessment Status",
      selector: (row) => (
        <span
          className={` ${
            getSurveyStatus(row.surveys) === "completed"
              ? "badge-success"
              : "badge-warning"
          } badge `}
        >
          {getSurveyStatus(row.surveys)}
        </span>
      ),
      sortable: true,
      className: "bold-header",
    },

    {
      name: "Payment Status",
      selector: (row) => (
        <span
          className={` ${
            row.payment_status !== "paid" ? "badge-warning" : "badge-success"
          } badge `}
        >
          {row.payment_status}
        </span>
      ),
      sortable: true,
      className: "bold-header",
    },

    {
      cell: (row) => (
        <div className="dropdown">
          <button
            className="btn  btn-sm text-xs"
            type="button"
            id={`dropdownMenuButton-${row.id}`}
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span className="fa fa-ellipsis-v"></span>
          </button>
          <div
            className="dropdown-menu text-xs"
            aria-labelledby={`dropdownMenuButton-${row.id}`}
          >
            <>
              {row.payment_status !== "paid" ? (
                < >
                  {USERROLE === "Team Lead" && (
                    <>
                    {row.membersCount > 0 ?   <button className="dropdown-item"><a  className="" href={`/main/payment/${row.id}`}>proceed to payment</a></button>:  <button className="dropdown-item"><a  className="" href={`/main/members_uplaod/${row.id}`}>Upload Members</a></button>}
                     
                    </>
                      
                  )}
                 
                </>

                
              ) : (
                <>
                  <a
                    className="text-white"
                    href={`/main/view_assessment/${row.id}`}
                  >
                    {USERROLE === "Team Lead" && (
                      <><button className="dropdown-item">View</button><a className="text-white" href={`/main/members_uplaod/${row.id}`}>

                          {getSurveyStatus(row.surveys) === 'completed' ? <p>assessment closed</p> :<button className="dropdown-item"><Link to={`/main/add_survey/${row.id}`}>Take assessment</Link> </button> } 


                        </a></>
                    )}
                  </a>

                  <span >
                    {USERROLE === "Team Member" && (
                      <button className="dropdown-item">
                        {getSurveyStatus(row.surveys) === 'completed' ? <p>assessment closed</p> : <Link to={`/main/add_survey/${row.id}`}>Take assessment</Link>}
                        {/* {row.status === "completed" ? "" : "Take Assessment"} */}
                      </button>
                    )}
                  </span>

                  {/* <span
                      className={` ${
                        getSurveyStatus(row.surveys) === "completed"
                          ? "badge-success"
                          : "badge-warning"
                      } badge `}
                    >
                      {getSurveyStatus(row.surveys)}
                    </span> */}
                </>
              )}

              {/* <button
                    className="dropdown-item"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleDelete(row.id);
                      handleShow();
                    }}
                  >
                    Delete
                  </button> */}
            </>
          </div>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      minWidth: "60px", // Set a minimum width for the dropdown button
    },
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      getAssessments();
      getMembersCountForAssessment()
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="content-wrapper" style={{ minHeight: "90vh" }}>
          <section className="content-header ">
            <div className="container-fluid ">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h5>Assessments </h5>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <a href="/main/dashboard">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Assessments</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid ">
              <div className="row">
                <div className="col-12 ">
                  <div className="card">
                    <div className="card-body ">
                      <div className="container   mt-5">
                        <div className="row ">
                          <div className="col-sm-6">
                            <h3 className="card-title text-bold">
                              Assessments
                            </h3>
                          </div>
                          <div className="col-sm-6 text-right">
                            {/* {( USERROLE === "Team Member"  ) &&(
                              <Link
                              to={`/main/add_survey`}
                              type="button"
                              className="btn btn-outline-primary btn-xs"
                              style={{ float: "right", marginRight: "10px" }}
                            >
                              Take Assessment
                            </Link>
                           
                          )} */}

                            {USERROLE === "Team Lead" && (
                              <Link
                                to={`/main/create_assessment`}
                                type="button"
                                className="btn btn-outline-primary btn-xs"
                                style={{ float: "right", marginRight: "10px" }}
                              >
                                Create Assessment
                              </Link>
                            )}

                            <button
                              type="button"
                              className="btn btn-outline-primary btn-xs"
                              style={{ float: "right", marginRight: "10px" }}
                              onClick={() => {
                                const csv = assessments.map((assessment) => ({
                                  Assessments: assessment.assessment_name,
                                }));
                                const csvData = convertToCSV(csv);
                                const blob = new Blob([csvData], {
                                  type: "text/csv",
                                });
                                const url = window.URL.createObjectURL(blob);
                                const a = document.createElement("a");
                                a.setAttribute("href", url);
                                a.setAttribute("download", "assessments.csv");
                                a.click();
                              }}
                            >
                              Export to CSV
                            </button>

                            <input
                              type="text"
                              className="form-control form-control-sm"
                              placeholder="Search..."
                              value={searchText}
                              onChange={handleSearch}
                              style={{
                                width: "200px",
                                float: "right",
                                marginRight: "10px",
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      {errorMessage && (
                           <div className=" container mt-2 bg-danger">
                           <p className="p-2 ">{errorMessage}</p>
                           </div>
                      )}
                   

                      <div className="container border rounded mt-5">
                        <div>
                          {loading}
                          <DataTable
                            columns={columns}
                            data={filteredAssessments}
                            fixedHeader
                            responsive={true}
                            selectableRows
                            progressPending={loading}
                            progressComponent={<TableLoader />}
                            onTableUpdate={() => setLoading(false)} // Reset loading state when table updates
                            striped // Enable row striping
                            highlightOnHover // Highlight row
                            pagination // Paginate
                          />
                        </div>

                        {show && (
                          <DeleteConfirm
                            handleConfirmDelete={handleConfirmDelete}
                            itemName={
                              assessments.find(
                                (assessment) =>
                                  assessment.id === selectedSurveyId
                              )?.id
                            }
                            handleClose={handleClose}
                            handleShow={handleShow}
                            show={show}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default Assessments;
