import React from 'react'


const Webinar = () => {
  return (
    <div className="content-wrapper" style={{minHeight: "90vh"}}>
     
     <section className="content mt-4">
          <div className="container-fluid ">
            <div className="row">
              <div className="col-12 ">
                <div className="card">
                  <div className="card-body ">
                    <div className="container   mt-5">
                    <h4 className=' text-lg text-bold text-center'>WEBINAR</h4>
                      <div className="row ">
                        <div className="col-sm-12">
                        
                        </div>

                        <p style={{fontSize: "16px", textAlign: "center" }}>Coming Soon !</p>
                        
                      </div>
                    </div>
  
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        
    </div>
  )
}

export default Webinar