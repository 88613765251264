import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../../../apiConfig";
import DataTable from "react-data-table-component";

import TableLoader from "../../common/TableLoader";
import { Link, useParams } from "react-router-dom";
import Spinner from "../../common/Spinner";
import DeleteConfirm from "../../common/DeleteConfirm";

function RecentAssessments() {
  const [assessments, setAssessments] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [hasPaid, setHasPaid] = useState(false);
  const [selectedSurveyId, setSelectedSurveyId] = useState(null);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [assessmentStatus, setAssessmentStatus] = useState("");
  const [membersCount, setMembersCount] = useState(null)

  //   const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const USERROLE = localStorage.getItem("role");
  const params = useParams()

  const handleConfirmDelete = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/survey/delete_assessment/${selectedSurveyId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      if (response.ok) {
        getAssessments();
      } else {
        console.log("Server error");
      }
    } catch (error) {
      console.log("An error occurred while deleting survey", error);
    }
    setTimeout(() => {
      setShow(false);
    }, 1000);
  };

  const handleDelete = (surveyId) => {
    setSelectedSurveyId(surveyId);
    handleShow();
  };

  function convertToCSV(data) {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((row) => Object.values(row).join(","));
    return [header, ...rows].join("\n");
  }

  const getMembersCountForAssessment = async (assessmentId) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/survey/team_members_count/${assessmentId}/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Server error: status ${response.status}`);
      }

      const data = await response.json();
      console.log(data)
      setMembersCount(data.team_members_count)
      return data.team_members_count;
    } catch (error) {
      console.log("Error fetching assessment members count", error);
      return 0;
    }
  };

  const getAssessments = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/survey/get_all_assessments/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        const paidAssessments = data.filter((paid) => paid.payment_status === 'paid')

        let assesmentsToShow
        if(USERROLE === 'Team Member') {
          assesmentsToShow = paidAssessments.slice(0, 15)
        } else {
          assesmentsToShow = data.slice(0, 15);
        }
       
        console.log("Assessments ", paidAssessments)

        const assessmentsWithMembersCount = await Promise.all(
          assesmentsToShow.map(async (assessment) => {
            const membersCount = await getMembersCountForAssessment(assessment.id);
            return { ...assessment, membersCount };
          })
        );

        const hasTeamLeadPaid = assessmentsWithMembersCount.some(
          (assessment) => assessment.payment_status === "paid"
        );
        setHasPaid(hasTeamLeadPaid);
        setAssessments(assessmentsWithMembersCount);
      }
    } catch (error) {
      console.log("Error fetching assessments", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const isSurveyComplete = (surveys) => {
    return surveys.every((survey) => survey.status === "completed");
  };

  const getSurveyStatus = (surveys) => {
    const userId = localStorage.getItem("userId");
    if (surveys.length === 0) return "pending";
    const userSurvey = surveys.find((survey) => survey.assessor === parseInt(userId));
    return userSurvey ? userSurvey.status : "pending";
  };


  const filteredAssessments = Array.isArray(assessments)
    ? assessments.filter((assessment) => {
        return (
          assessment.assessment_name
            .toString()
            .toLowerCase()
            .includes(searchText.toLowerCase()) || ""
        );
      })
    : [];

  const columns = [
    {
      name: "Assessment Name",
      selector: (row) => `${row.assessment_name}`,
      sortable: true,
      className: "bold-header",
    },

    {
        name: "Team Lead",
        selector: (row) => `${row.user}`,
        sortable: true,
        className: "bold-header",
      },

      {
        name: "Members ",
        selector: (row) => `${row.membersCount}`,
        sortable: true,
        className: "bold-header",
      },

      {
        name: "Date created",
        selector: (row) => `${new Date(row.created_at).toLocaleDateString()}`,
        sortable: true,
        className: "bold-header",
      },

      

  

    {
      name: "Assessment Status",
      selector: (row) => (
        <span
          className={` ${
            getSurveyStatus(row.surveys) === "completed"
              ? "badge-success"
              : "badge-warning"
          } badge `}
        >
          {getSurveyStatus(row.surveys)}
        </span>
      ),
      sortable: true,
      className: "bold-header",
    },

    {
      name: "Payment Status",
      selector: (row) => (
        <span
          className={` ${
            row.payment_status !== "paid" ? "badge-warning" : "badge-success"
          } badge `}
        >
          {row.payment_status}
        </span>
      ),
      sortable: true,
      className: "bold-header",
    },


  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      getAssessments();
      getMembersCountForAssessment()
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="" >
          <section className="content-header ">
            <div className=" ">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h5></h5>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <a href="/main/dashboard">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Recent Assessments</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="">
            <div className="">
              <div className="row">
                <div className="col-12 ">
                  <div className="card">
                    <div className="card-body ">
                      <div className="">
                        <div className="row ">
                          <div className="col-sm-6">
                            <h3 className="card-title text-bold">
                             Recent Assessments
                            </h3>
                          </div>
                          <div className="col-sm-6 text-right">
                        

                           

                            <button
                              type="button"
                              className="btn btn-outline-primary btn-xs"
                              style={{ float: "right", marginRight: "10px" }}
                              onClick={() => {
                                const csv = assessments.map((assessment) => ({
                                  Assessments: assessment.assessment_name,
                                }));
                                const csvData = convertToCSV(csv);
                                const blob = new Blob([csvData], {
                                  type: "text/csv",
                                });
                                const url = window.URL.createObjectURL(blob);
                                const a = document.createElement("a");
                                a.setAttribute("href", url);
                                a.setAttribute("download", "assessments.csv");
                                a.click();
                              }}
                            >
                              Export to CSV
                            </button>

                            <input
                              type="text"
                              className="form-control form-control-sm"
                              placeholder="Search..."
                              value={searchText}
                              onChange={handleSearch}
                              style={{
                                width: "200px",
                                float: "right",
                                marginRight: "10px",
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="border rounded mt-2">
                        <div>
                          {loading}
                          <DataTable
                            columns={columns}
                            data={filteredAssessments}
                            fixedHeader
                            responsive={true}
                            selectableRows
                            progressPending={loading}
                            progressComponent={<TableLoader />}
                            onTableUpdate={() => setLoading(false)} // Reset loading state when table updates
                            striped // Enable row striping
                            highlightOnHover // Highlight row
                            pagination // Paginate
                          />
                        </div>

                        {show && (
                          <DeleteConfirm
                            handleConfirmDelete={handleConfirmDelete}
                            itemName={
                              assessments.find(
                                (assessment) =>
                                  assessment.id === selectedSurveyId
                              )?.id
                            }
                            handleClose={handleClose}
                            handleShow={handleShow}
                            show={show}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default RecentAssessments;
