import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API_BASE_URL } from "../../apiConfig";

const Menu = () => {

  const [appName, setAppName] = useState([]);

  const USERROLE = localStorage.getItem("role");

  const getApps = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/app_config/app_configs/`);
      const data = await response.json();
      if (data.length === 0) {
        setAppName("APP");
      } else {
        setAppName(data[0]?.app_name);
      }

      console.log(data[0]?.app_name);
    } catch (error) {
      console.log("error while fetching counties");
    }
  };

  useEffect(() => {
    getApps();
    
  }, []);


  const textStyle = "text-dark"

  return (
    <div style={{ zIndex: 100, backgroundColor: "blue", fontSize:"17px" , fontWeight: "500"}}>
      {/* Main Sidebar Container */}
      <aside style={{}} className="main-sidebar    elevation-4 ">
        {/* Brand Logo */}
        <a href="/main/dashboard" className="brand-link">
          <img
            src="/dist/img/AdminLTELogo.png"
            alt="AdminLTE Logo"
            className="brand-image img-circle elevation-3"
            style={{ opacity: ".8" }}
          />
          <span
            style={{ color: "#1b1553" }}
            className="brand-text  font-weight-bold "
          >
            {appName}
          </span>
        </a>
        {/* Sidebar */}
        <div className="sidebar" style={{ backgroundColor: "white" }}>
          {/* Sidebar Menu */}
          <nav style={{ overflowX: "hidden" }} className="mt-2">
            <ul
              className="nav nav-pills  nav-sidebar flex-column "
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item ">
                <Link
                  to="/main/dashboard"
                  className="nav-link active app__bgColor"
                  style={{ backgroundColor: "white " }}
                >
                  <i className="fa fa-th-large nav-icon fa-xs text-dark "  />
                  <p className="text-dark">Dashboard</p>
                </Link>
              </li>

              <li className="nav-item ">
                <Link to="/main/user_profile" className="nav-link ">
                  <i className="far fa-user-circle nav-icon fa-xs" />
                  <p className={textStyle}>Profile</p>
                </Link>
              </li>

              <li className="nav-item ">
                <Link to="/main/assessments" className="nav-link ">
                  <i className="nav-icon fas fa-tasks" />
                  <p className={textStyle}>Assessments</p>
                </Link>
              </li>

              <li className="nav-item">
                <Link to="/main/webinar" className="nav-link">
                  <i className="fas fa-video nav-icon fa-xs" />
                  <p className={textStyle}>Webinar</p>
                </Link>
              </li>

              {(USERROLE === "Team Lead" || USERROLE === "Admin") && (
                <>
                

                  <li className="nav-item">
                    <li className="nav-link" style={{ cursor: "pointer" }}>
                      <i className="fas fa-people-carry nav-icon" />
                      <p className={textStyle}>
                        Teams
                        <i className="fas fa-angle-left right" style={{fontSize: "16px" , color: "grey"}} />
                      </p>
                    </li>

                    <ul className="nav nav-treeview ml-2">
                      <li className="nav-item ml-2">
                        <Link
                          to="/main/teams"
                          className="nav-link"
                          style={{ cursor: "pointer" }}
                        >
                          <i className="fa fa-user nav-icon "></i>
                          <p className={textStyle}>Teams</p>
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li className="nav-item">
                    <li className="nav-link" style={{ cursor: "pointer" }}>
                      <i className="nav-icon fas fa-dollar-sign" />
                      <p className={textStyle}>
                        Payment
                        <i className="fas fa-angle-left right" style={{fontSize: "16px" , color: "grey"}} />
                      </p>
                    </li>

                    <ul className="nav nav-treeview ml-2">
                      <li className="nav-item ml-2">
                        <Link
                          to="/main/transactions"
                          className="nav-link"
                          style={{ cursor: "pointer" }}
                        >
                          <i className="nav-icon fas fa-money-bill-wave" />
                          <p className={textStyle}>Transactions</p>
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li className="nav-item">
                    <li className="nav-link" style={{ cursor: "pointer" }}>
                      <i className="nav-icon fas fa-history" />
                      <p className={textStyle}>
                        History
                        <i className="fas fa-angle-left right" style={{fontSize: "16px" , color: "grey"}} />
                      </p>
                    </li>

                    <ul className="nav nav-treeview ml-2">
                      <li className="nav-item ml-2">
                        <Link
                          to="/main/audit_trail"
                          className="nav-link"
                          style={{ cursor: "pointer" }}
                        >
                          <i className="nav-icon fas fa-book" />
                          <p className={textStyle}>Last login</p>
                        </Link>
                      </li>
                    </ul>
                  </li>


                  <li className="nav-item">
                    <li className="nav-link" style={{ cursor: "pointer" }}>
                      <i className="fa fa-users nav-icon" />
                      <p className={textStyle}>
                        Users
                        <i className="fas fa-angle-left right" style={{fontSize: "16px" , color: "grey"}} />
                      </p>
                    </li>

                    <ul className="nav nav-treeview ml-2">
                      <li className="nav-item ml-2">
                        <Link
                          to="/main/users"
                          className="nav-link"
                          style={{ cursor: "pointer" }}
                        >
                          <i className="fa fa-user nav-icon "></i>
                          <p className={textStyle}>Users List</p>
                        </Link>
                      </li>

                      <li className="nav-item ml-2">
                        <Link
                          to="/main/user_roles"
                          className="nav-link"
                          style={{ cursor: "pointer" }}
                        >
                          <i className="fas fa-users-cog nav-icon"></i>
                          <p className={textStyle}>Roles List</p>
                        </Link>
                      </li>

                      <li className="nav-item ml-2">
                        <Link
                          to="/main/roles"
                          className="nav-link"
                          style={{ cursor: "pointer" }}
                        >
                          <i className="fas fa-user-tag nav-icon"></i>
                          <p className={textStyle}>Assign Roles</p>
                        </Link>
                      </li>
                    </ul>
                  </li>

                  {USERROLE === "Admin" && (

                  <li className="nav-item">
                    <li className="nav-link" style={{ cursor: "pointer" }}>
                      <i className="nav-icon fa fa-cog fa-sx" />
                      <p className={textStyle}>
                        Settings
                       <i className="fas fa-angle-left right" style={{fontSize: "16px" , color: "grey"}} />
                      </p>
                    </li>

               
                         <ul className="nav nav-treeview ml-2">
                         <li className="nav-item">
                           <li className="nav-link" style={{ cursor: "pointer" }}>
                             <i className="fa fa-cog nav-icon" />
                             <p className={textStyle}>
                               App Settings
                              <i className="fas fa-angle-left right" style={{fontSize: "16px" , color: "grey"}} />
                             </p>
                           </li>
   
                           <ul className="nav nav-treeview ml-2">
                             <li className="nav-item ml-2">
                               <Link
                                 to="/main/appConfig"
                                 className="nav-link"
                                 style={{ cursor: "pointer" }}
                               >
                                 <i className="fa fa-tools nav-icon "></i>
                                 <p className={textStyle}>App Config</p>
                               </Link>
                             </li>
                           </ul>
   
                           <ul className="nav nav-treeview ml-2">
                             <li className="nav-item ml-2">
                               <Link
                                 to="/main/email_config"
                                 className="nav-link"
                                 style={{ cursor: "pointer" }}
                               >
                                 <i className="fa fa-tools nav-icon "></i>
                                 <p className={textStyle}>Email Config</p>
                               </Link>
                             </li>
                           </ul>
                         </li>
                       </ul>
   
   
                    

                   


                  </li>
                )}
                </>
              )}
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
    </div>
  );
};

export default Menu;
