import React, { useEffect, useState } from "react";
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";

import Select from "react-select";
import { API_BASE_URL } from "../../../apiConfig";
import Spinner from "../../common/Spinner";

const CreateUser = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [numberError, setNumberError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const pattern = new RegExp(/^\d{1,10}$/);

  const handlePasswordShow = () => {
    setShowPassword(!showPassword);
  };

  const [formData, setFormData] = useState({
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password2: "",

    personal_info: {
      country: "",
      city: "",
      chaptername: "",
      teamlead: "",
      phone_number: "",
    },
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (
      name === "country" ||
      name === "city" ||
      name === "chaptername" ||
      name === "teamlead" ||
      name === "phone_number"
    ) {
      setFormData({
        ...formData,
        personal_info: {
          ...formData.personal_info,
          [name]: value,
        },
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleDataSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);

      const response = await fetch(`${API_BASE_URL}/api/accounts/signup/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSuccessMessage("Account created successfully!");

        setTimeout(() => {
          navigate("/main/users");
          setLoading(false);
        }, 3000);
      } else {
        setErrorMessage("Failed to create account. Please contact Admin.");
        setLoading(false);
      }
    } catch (error) {
      setErrorMessage(
        "An error occurred while creating account. Please contact Admin."
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setErrorMessage("");
    }, 5000);
  }, [errorMessage]);

  useEffect(() => {
    const getCountries = async () => {
      const response = await fetch("https://restcountries.com/v3.1/all");
      const data = await response.json();
      const countryOptions = data.map((country) => ({
        value: `${country.idd.root}${
          country.idd.suffixes ? country.idd.suffixes[0] : ""
        }`,
        label: `${country.name.common} (${country.idd.root}${
          country.idd.suffixes ? country.idd.suffixes[0] : ""
        })`,
      }));
      setCountries(countryOptions);
    };

    getCountries();
  }, []);

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setSelectedCountryCode(selectedOption ? selectedOption.value : "");
    setFormData({
      ...formData,
      personal_info: {
        ...formData.personal_info,
        country: selectedOption ? selectedOption.label : "",
      },
    });
    updatePhoneNumber(selectedOption ? selectedOption.value : "", phoneNumber);
  };

  const handlePhoneNumberChange = (e) => {
    const number = e.target.value.replace(selectedCountryCode, "");
    if (pattern.test(number)) {
      setPhoneNumber(number);
      updatePhoneNumber(selectedCountryCode, number);
      setNumberError("");
    } else {
      setNumberError("Enter a valid phone number (up to 10 digits).");
    }
  };

  const updatePhoneNumber = (countryCode, number) => {
    const phoneNumberWithCode = `${countryCode}${number}`;
    setFormData((prevFormData) => ({
      ...prevFormData,
      personal_info: {
        ...prevFormData.personal_info,
        phone_number: phoneNumberWithCode,
      },
    }));
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="content-wrapper" style={{ minHeight: "90vh" }}>
          <section className="content-header">
            <div className="container-fluid">
              <div className="row ">
                <div className="col-sm-6">
                  <h5>Add User</h5>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <a href="/main/dashboard">Home</a>
                    </li>
                    <li className="breadcrumb-item active mb-2">Add User </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="container ">
                        <div className="row">
                          <div className="col-sm-6">
                            <h3 className="card-title text-bold my-2">
                              Add User{" "}
                            </h3>
                          </div>
                          <div className="col-sm-6 text-right"></div>
                        </div>
                      </div>
                      <div className=" container mx-auto">
                        {successMessage && (
                          <p className="p-2 rounded bg-success">
                            {successMessage}
                          </p>
                        )}
                      </div>

                      <div className="container border rounded bg-white shadow p-4 ">
                        <form
                          onSubmit={handleDataSubmit}
                          style={{ margin: "0 10px" }}
                        >
                          <div className="">
                            <div className="row">
                              <div className="col-md-6">
                                <div>
                                  <label style={{ fontSize: "14px" }}>
                                    Username
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Username"
                                    className="form-control mb-2 rounded border outline-primary"
                                     style={{ borderColor: "#989898" }}
                             
                                    name="username"
                                    value={formData.username}
                                    onChange={handleChange}
                                    required
                                  />
                                </div>

                                <div>
                                  <label style={{ fontSize: "14px" }}>
                                    Last name
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Last Name"
                                    className="form-control mb-2 rounded border outline-primary"
                                    style={{ borderColor: "#989898" }}
                                    name="last_name"
                                    value={formData.last_name}
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div>
                                  <label style={{ fontSize: "14px" }}>
                                    First name
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="First Name"
                                    className="form-control mb-2 rounded border outline-primary"
                                     style={{ borderColor: "#989898" }}
                                    name="first_name"
                                    value={formData.first_name}
                                    onChange={handleChange}
                                    required
                                  />
                                </div>

                                <div>
                                  <label style={{ fontSize: "14px" }}>
                                    Email
                                  </label>
                                  <input
                                    type="email"
                                    placeholder="Email"
                                    className="form-control mb-2 rounded border outline-primary"
                                     style={{ borderColor: "#989898" }}
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                              </div>
                            </div>

                            {/* Row 2 */}

                            <div className="row">
                              <div className="col-md-6">
                                <div>
                                  <label style={{ fontSize: "14px" }}>
                                    Country
                                  </label>
                                  <Select
                                    options={countries}
                                    value={selectedCountry}
                                    onChange={handleCountryChange}
                                    placeholder="Select country..."
                                    isSearchable={true}
                                  />
                                </div>

                                <div className=" my-2">
                                  <label style={{ fontSize: "14px" }}>
                                    Team Name(optional)
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Chapter Name"
                                    className="form-control mb-2 rounded border outline-primary"
                                     style={{ borderColor: "#989898" }}
                                    name="chaptername"
                                    value={formData.personal_info.chaptername}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div>
                                  <label style={{ fontSize: "14px" }}>
                                    City
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="City"
                                    className="form-control mb-2 rounded border outline-primary"
                                     style={{ borderColor: "#989898" }}
                                    name="city"
                                    value={formData.personal_info.city}
                                    onChange={handleChange}
                                  />
                                </div>

                                <div>
                                  <label style={{ fontSize: "14px" }}>
                                    Team Lead(optional)
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Team Lead"
                                    className="form-control  rounded"
                                    name="teamlead"
                                    value={formData.personal_info.teamlead}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                            </div>

                            {/* Row 3 */}

                            <div className="row">
                              <div className="col-md-6">
                                <div>
                                  <label style={{ fontSize: "14px" }}>
                                    Phone Number
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Phone Number"
                                    className="form-control mb-2 rounded border outline-primary"
                                     style={{ borderColor: "#989898" }}
                                    name="phone_number"
                                    value={`${selectedCountryCode}${phoneNumber}`}
                                    onChange={handlePhoneNumberChange}
                                  />
                                  {numberError && (
                                    <p className=" text-danger">
                                      {numberError}
                                    </p>
                                  )}
                                </div>

                                <div>
                                  <label style={{ fontSize: "14px" }}>
                                    Enter password
                                  </label>
                                  <div className="input-group mb-2">
                                    <input
                                      type={showPassword ? "text" : "password"}
                                      placeholder="Password"
                                      className="form-control border-right-0"
                                      name="password"
                                      value={formData.password}
                                      onChange={handleChange}
                                      required
                                    />
                                    <div className="input-group-append">
                                      <span
                                        className="input-group-text bg-white border-left-0"
                                        onClick={handlePasswordShow}
                                      >
                                        {showPassword ? (
                                          <FaRegEye />
                                        ) : (
                                          <FaRegEyeSlash />
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div>
                                  <label style={{ fontSize: "14px" }}>
                                    Email address
                                  </label>
                                  <input
                                    type="email"
                                    placeholder="Email Address"
                                    className="form-control mb-2 rounded border outline-primary"
                                     style={{ borderColor: "#989898" }}
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                  />
                                </div>

                                <div>
                                  <label style={{ fontSize: "14px" }}>
                                    Re-enter password
                                  </label>
                                  <div className="input-group mb-2">
                                    <input
                                      type={showPassword ? "text" : "password"}
                                      placeholder="Confirm Password"
                                      className="form-control border-right-0"
                                      name="password2"
                                      value={formData.password2}
                                      onChange={handleChange}
                                      required
                                    />
                                    <div className="input-group-append">
                                      <span
                                        className="input-group-text bg-white border-left-0"
                                        onClick={handlePasswordShow}
                                      >
                                        {showPassword ? (
                                          <FaRegEye />
                                        ) : (
                                          <FaRegEyeSlash />
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="card-footer">
                            <button
                              type="submit"
                              className="btn btn-sm  app__bgColor text-white font-weight-bold rounded my-4"
                            >
                              {loading ? (
                                <>
                                  <span
                                    className="spinner-border  spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="ml-2">
                                    Creating account...
                                  </span>
                                </>
                              ) : (
                                "Create Account"
                              )}
                            </button>
                          </div>
                        </form>

                        {errorMessage && (
                          <p className="alert alert-danger">{errorMessage}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default CreateUser;
