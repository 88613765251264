import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../../../apiConfig";
import DataTable from "react-data-table-component";
import Spinner from "../../common/Spinner";

import TableLoader from "../../common/TableLoader";
import { Link } from "react-router-dom";
import DeleteConfirm from "../../common/DeleteConfirm";
import useTokenExpiration from "../../../useTokenExpiration";

const UsersList = () => {
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true)
  const [selectedUserId, setSelectedUserId] = useState(null)

  const { errorMessage, handleTokenExpiry } = useTokenExpiration();

  const [show, setShow] = useState(false);

  const USERROLE = localStorage.getItem("role")

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false)



  const handleDelete = (userId) => {
    setSelectedUserId(userId);
  };
 






  function convertToCSV(data) {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((row) => Object.values(row).join(","));
    return [header, ...rows].join("\n");
  }

  const getUsers = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/api/accounts/users/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      if(response.status === 401) {
        handleTokenExpiry()
        return
      }


      const data = await response.json();
      setUsers(Array.isArray(data) ? data : []);
      console.log("Users", data);
    } catch (error) {
      console.log("an error occurred while fetching users");
    } finally {
      setLoading(false);
    }
  };


  const handleConfirmDelete = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/accounts/delete_user_account/${selectedUserId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      if(response.status === 401) {
        handleTokenExpiry()
        return
      }

      if (response.ok) {
        getUsers();
      } else {
        console.log("server error");
      }
    } catch (error) {
      console.log("An error occured while deleting user", error);
    }
    setTimeout(() => {
      setShow(false);
    }, 1000);
  };


  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredUsers = users.filter((user) => {
    return (
      user.username.toLowerCase().includes(searchText.toLowerCase()) ||
      user.first_name.toLowerCase().includes(searchText.toLowerCase()) ||
      user.last_name.toLowerCase().includes(searchText.toLowerCase())||
      user.email.toLowerCase().includes(searchText.toLowerCase())
    );
  });

  const columns = [
    {
      name: "Username",
      selector: (row) => `${row.username}`,
      sortable: true,
      className: "bold-header",
    },
    {
      name: "First name",
      selector: (row) => `${row.first_name} `,
      sortable: true,
      className: "bold-header",
    },

    {
        name: "Last name",
        selector: (row) => `${row.last_name} `,
        sortable: true,
        className: "bold-header",
      },

      {
        name: "Email",
        selector: (row) => `${row.email} `,
        sortable: true,
        className: "bold-header",
      },

      {
        cell: (row) => (
          <div className="dropdown">
            <button
              className="btn  btn-sm text-xs"
              type="button"
              id={`dropdownMenuButton-${row.id}`}
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="fa fa-ellipsis-v"></span>
            </button>
            <div
              className="dropdown-menu text-xs"
              aria-labelledby={`dropdownMenuButton-${row.id}`}
            >
             
                <>
                {USERROLE === "Admin" && (
                     <button
                     className="dropdown-item"
                     style={{ cursor: "pointer" }}
                     onClick={() => {
                       handleDelete(row.id);
                       handleShow();
                     }}
                   >
                     Delete
                   </button>
                )}
                 
                </>
          
            </div>
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        minWidth: "60px", // Set a minimum width for the dropdown button
      },

      

    
  ];

  useEffect(() => {

    const timer = setTimeout(() => {
      getUsers();
      setIsLoading(false)
    }, 1000);

    return () => clearTimeout(timer)

    
  }, []);

  return (
    <>
    {isLoading ? (
      <Spinner />
    ) : (
      <div className="content-wrapper" style={{minHeight: "90vh"}}>
      <section className="content-header ">
        <div className="container-fluid ">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h5>Users List </h5>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/main/dashboard">Home</a>
                </li>
                <li className="breadcrumb-item active">Users List</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-12 ">
              <div className="card">
                <div className="card-body ">
                  <div className="container   mt-5">
                    <div className="row ">
                      <div className="col-sm-6">
                        <h3 className="card-title text-bold">Users List</h3>
                      </div>
                      <div className="col-sm-6 text-right">


                      {( USERROLE === 'Admin')&& (
                               <Link
                               to={`/main/create_user`}
                                   type="button"
                                   className="btn btn-outline-primary btn-xs"
                                   style={{ float: "right", marginRight: "10px" }}
                                 >
                                   Add User 
                                 </Link>
                          )}
  

                        <button
                          type="button"
                          className="btn btn-outline-primary btn-xs"
                          style={{ float: "right", marginRight: "10px" }}
                          onClick={() => {
                            const csv = users.map((user) => ({
                              username: user.username,
                              Full_Name: `${user.first_name} ${user.last_name}`,
                              Email: user.email,
                              roles: user.role
                            }));
                            const csvData = convertToCSV(csv);
                            const blob = new Blob([csvData], {
                              type: "text/csv",
                            });
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement("a");
                            a.setAttribute("href", url);
                            a.setAttribute("download", "audit_trail.csv");
                            a.click();
                          }}
                        >
                          Export to CSV
                        </button>

                        <input
                          type="text"
                          className="form-control form-control-sm"
                          placeholder="Search..."
                          value={searchText}
                          onChange={handleSearch}
                          style={{
                            width: "200px",
                            float: "right",
                            marginRight: "10px",
                          }}
                        />
                      </div>
                    </div>
                  </div>


                  {errorMessage && (
                           <div className=" container mt-2 bg-danger">
                           <p className="p-2 ">{errorMessage}</p>
                           </div>
                      )}

                  <div className="container border rounded mt-5">
                    <div>
                      {loading}
                      <DataTable
                        columns={columns}
                        data={filteredUsers}
                        fixedHeader
                        responsive={true}
                        selectableRows
                        progressPending={loading}
                        progressComponent={<TableLoader />}
                        onTableUpdate={() => setLoading(false)} // Reset loading state when table updates
                        striped // Enable row striping
                        highlightOnHover // Highlight row
                        pagination // Paginate
                      />
                    </div>

                    {show && (
                        <DeleteConfirm
                          handleConfirmDelete={handleConfirmDelete}
                          itemName={
                            users.find(
                              (user) => user.id === selectedUserId
                            )?.username
                          }
                          handleClose={handleClose}
                          handleShow={handleShow}
                          show={show}
                        />
                      )}


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    )}
   
    </>
  );
}

export default UsersList;
