import React, { useState } from "react";
import { Link } from "react-router-dom";




const PaymentSucess = () => {
 

  return (
    <div className="content-wrapper" style={{ minHeight: "90vh" }}>
     

      <section className="content-header ">
        <div className="container-fluid ">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h5>Assessment Payment </h5>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/main/dashboard">Home</a>
                </li>
                <li className="breadcrumb-item active">Assessment Payment</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-12 ">
              <div className="card">
                <div className="card-body ">
                  <div className="container">
                    <div className="row ">
                      {/* <div className="col-sm-6">
                        <h3 className="card-title text-bold">Assessments Payment</h3>
                      </div> */}
                    </div>
                  </div>

                  <div className="container card shadow-md bg-white border rounded p-3 mt-5">
                    <div className=" text-center">
                        <img className=" my-3" width="30px" src="/images/sucess.png" alt="sucess icon" />
                    <h5 className=" text-bold text-center">Payment Sucessful</h5>
                   <p style={{fontSize: "14px"}}>Thank you for making your payment. Now you can proceed take the assessment</p>
                   <Link to={`/main/assessments`}><button className=" btn app__bgColor text-white">Continue to assessment</button></Link>
                    </div>
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PaymentSucess;
