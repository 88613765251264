import React, { useEffect, useState } from "react";
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../apiConfig";
import Select from "react-select";


function Signup() {
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [numberError, setNumberError] = useState("")

  const pattern = new RegExp(/^\d{1,10}$/);
  

  const handlePasswordShow = () => {
    setShowPassword(!showPassword);
  };

  

  const [formData, setFormData] = useState({
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password2: "",
   
    personal_info: {
      country: "",
      city: "",
      chaptername: "",
      teamlead: "",
      phone_number: "",
    },
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (
      name === "country" ||
      name === "city" ||
      name === "chaptername" ||
      name === "teamlead" ||
      name === "phone_number"
    ) {
      setFormData({
        ...formData,
        personal_info: {
          ...formData.personal_info,
          [name]: value,
        },
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleDataSubmit = async (event) => {

 


    event.preventDefault();
    try {
      setLoading(true);

      const response = await fetch(`${API_BASE_URL}/api/accounts/signup/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
       
        setSuccessMessage("Account created successfully!");

        setTimeout(() => {
          navigate("/activate_account");
          setLoading(false);
        }, 3000);
      } else {
        setErrorMessage(
          "Failed to create account. Please contact Admin."
        );
        setLoading(false);
      }
    } catch (error) {
      setErrorMessage(
        "An error occurred while creating account. Please contact Admin."
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setErrorMessage("");
    }, 5000);
  }, [errorMessage]);

  useEffect(() => {
    const getCountries = async () => {
      const response = await fetch("https://restcountries.com/v3.1/all");
      const data = await response.json();
      const countryOptions = data.map((country) => ({
        value: `${country.idd.root}${
          country.idd.suffixes ? country.idd.suffixes[0] : ""
        }`,
        label: `${country.name.common} (${country.idd.root}${
          country.idd.suffixes ? country.idd.suffixes[0] : ""
        })`,
      }));
      setCountries(countryOptions);
    };

    getCountries();
  }, []);

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setSelectedCountryCode(selectedOption ? selectedOption.value : "");
    setFormData({
      ...formData,
      personal_info: {
        ...formData.personal_info,
        country: selectedOption ? selectedOption.label : "",
      },
    });
    updatePhoneNumber(selectedOption ? selectedOption.value : "", phoneNumber);
  };

  const handlePhoneNumberChange = (e) => {
    const number = e.target.value.replace(selectedCountryCode, "");
    if (pattern.test(number)) {
      setPhoneNumber(number);
      updatePhoneNumber(selectedCountryCode, number);
      setNumberError(""); 
    } else {
      setNumberError("Enter a valid phone number (up to 10 digits).");
    }
  };

  const updatePhoneNumber = (countryCode, number) => {
    const phoneNumberWithCode = `${countryCode}${number}`;
    setFormData((prevFormData) => ({
      ...prevFormData,
      personal_info: {
        ...prevFormData.personal_info,
        phone_number: phoneNumberWithCode,
      },
    }));
  };

  return (
    <div className="d-flex align-items-center justify-content-center min-vh-100">
      <div className="login-container container mx-auto">
        <div className="d-flex align-items-center justify-content-center flex-column bg-white custom-card w-100 w-md-50 rounded p-4 shadow">
          <h5 className="text-center font-weight-bold my-4">Create account</h5>
          {successMessage && (
            <p className="p-2 rounded bg-success">{successMessage}</p>
          )}

          <div className="w-100">
            <form onSubmit={handleDataSubmit} className="d-flex flex-column">
              <div>
                <label style={{ fontSize: "14px", color: "gray" }}>
                  Username
                </label>
                <input
                  type="text"
                  placeholder="Username"
                  className="form-control mb-2 rounded"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  required
                />
              </div>

              <div>
                <label style={{ fontSize: "14px", color: "gray" }}>
                  First name
                </label>
                <input
                  type="text"
                  placeholder="First Name"
                  className="form-control mb-2 rounded"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleChange}
                  required
                />
              </div>

              <div>
                <label style={{ fontSize: "14px", color: "gray" }}>
                  Last name
                </label>
                <input
                  type="text"
                  placeholder="Last Name"
                  className="form-control mb-2 rounded"
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleChange}
                  required
                />
              </div>

              <div>
                <label style={{ fontSize: "14px", color: "gray" }}>
                  Country
                </label>
                <Select
                  options={countries}
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  placeholder="Select country..."
                  isSearchable={true}
                />
              </div>

              <div>
                <label style={{ fontSize: "14px", color: "gray" }}>City</label>
                <input
                  type="text"
                  placeholder="City"
                  className="form-control mb-2 rounded"
                  name="city"
                  value={formData.personal_info.city}
                  onChange={handleChange}
                />
              </div>

              <div style={{ display: "none" }}>
                <label style={{ fontSize: "14px", color: "gray" }}>
                  Team Name
                </label>
                <input
                  type="text"
                  placeholder="Chapter Name"
                  className="form-control mb-2 rounded"
                  name="chaptername"
                  value={formData.personal_info.chaptername}
                  onChange={handleChange}
                />
              </div>

              <div style={{ display: "none" }}>
                <label style={{ fontSize: "14px", color: "gray" }}>
                  Team Lead
                </label>
                <input
                  type="text"
                  placeholder="Team Lead"
                  className="form-control mb-2 rounded"
                  name="teamlead"
                  value={formData.personal_info.teamlead}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label style={{ fontSize: "14px", color: "gray" }}>
                  Phone Number
                </label>
                <input
                  type="text"
                  placeholder="Phone Number"
                  className="form-control mb-2 rounded"
                  name="phone_number"
                  value={`${selectedCountryCode}${phoneNumber}`}
                  onChange={handlePhoneNumberChange}
                />
                {numberError && <p className=" text-danger">{numberError}</p>}

              
              </div>

              <div>
                <label style={{ fontSize: "14px", color: "gray" }}>
                  Email address
                </label>
                <input
                  type="email"
                  placeholder="Email Address"
                  className="form-control mb-2 rounded"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>

              <div>
                <label style={{ fontSize: "14px", color: "gray" }}>
                  Enter password
                </label>
                <div className="input-group mb-2">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    className="form-control border-right-0"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                  <div className="input-group-append">
                    <span
                      className="input-group-text bg-white border-left-0"
                      onClick={handlePasswordShow}
                    >
                      {showPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                    </span>
                  </div>
                </div>
              </div>

              <div>
                <label style={{ fontSize: "14px", color: "gray" }}>
                  Re-enter password
                </label>
                <div className="input-group mb-2">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    className="form-control border-right-0"
                    name="password2"
                    value={formData.password2}
                    onChange={handleChange}
                    required
                  />
                  <div className="input-group-append">
                    <span
                      className="input-group-text bg-white border-left-0"
                      onClick={handlePasswordShow}
                    >
                      {showPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                    </span>
                  </div>
                </div>
              </div>

              {/* <div>
                <label style={{ fontSize: "14px", color: "gray" }}>Role</label>
                <select
                  className="form-control mb-2 rounded"
                  name="role_name"
                  value={formData.role_name}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Role</option>
                  <option value="Member">Team Member</option>
                  <option value="Admin">Admin</option>
                  <option value="Team Lead">Team Lead</option>
                  <option value="Director">Director</option>
                </select>
              </div> */}
              <button
                type="submit"
                className="btn  app__bgColor text-white font-weight-bold rounded my-4"
              >
                {loading ? (
                  <>
                    <span
                      className="spinner-border  spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="ml-2">Creating account...</span>
                  </>
                ) : (
                  "Create Account"
                )}
              </button>
            </form>
            {errorMessage && (
              <p className="alert alert-danger">{errorMessage}</p>
            )}
          </div>

          <Link
            to={`/`}
            className="text-primary"
            style={{ fontSize: "14px", fontWeight: "500" }}
          >
            Already have an account? <span>Login</span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Signup;
