import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

function PaymentModal() {

  const [show, setShow] = useState(true)
  const handleClose = () => {
    setShow(false);
  };

  return (
    <div className=" ">
      <Modal
        
       
        show={show}
        onHide={handleClose}
      >
        
        <Modal.Header closeButton>
          <Modal.Title>Payment Required</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <p style={{fontSize: "14px"}}>Please make a payment to proceed to assessment .</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Back
          </Button>
          <Link to={`/main/payment`}>
          <Button variant="primary">
            Proceed to Payment
          </Button>
          </Link>
          
        </Modal.Footer>
      
      </Modal>
    </div>
  );
}

export default PaymentModal;
