import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../../../apiConfig";
import { useNavigate, useParams } from "react-router-dom";

const UpdateUserRole = () => {
  const navigate = useNavigate();
  const params = useParams()
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({
    role: ""
  });

 



  const getRole = async () => {
  
    try {
      const response = await fetch(`${API_BASE_URL}/api/accounts/get_user_role/${params.id}/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      const data = await response.json();
      setFormData(data);
      console.log("Roles", data);
    } catch (error) {
      console.log("an error occurred while fetching roles");
    } finally {
     
    }
  };

  

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

 

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${API_BASE_URL}/api/accounts/update_user_role/${params.id}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log("Role updated successfully");
        navigate("/main/user_roles");
      } else {
        console.log("Failed to create role");
      }
    } catch (error) {
      console.error("An error occurred while creating role", error);
    }
  };


  useEffect(() => {
    getRole()
  }, [params.id])

  return (
    <div className="content-wrapper" style={{ minHeight: "90vh" }}>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h5>User Roles</h5>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/main/dashboard">Home</a>
                </li>
                <li className="breadcrumb-item active">Update User Role</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="container mt-5">
                    <div className="row">
                      <div className="col-sm-6">
                        <h3 className="card-title text-bold">Add User Role</h3>
                      </div>
                      <div className="col-sm-6 text-right"></div>
                    </div>
                  </div>

                  <div className="container border rounded mt-5">
                    <form onSubmit={handleSubmit} style={{ margin: "0 10px" }}>
                      <div className="mt-4">
                        <div>
                          <h6 className="text-bold">User Role name</h6>
                          <input
                            type="text"
                            className="form-control mb-2 rounded"
                            name="role"
                            value={formData.role}
                            onChange={handleChange}
                            required
                          />
                        </div>

                        
                      </div>

                      <div className="card-footer">
                        <button
                          type="submit"
                          className="btn app__bgColor text-white  text-bold btn-sm"
                        >
                          Save Details
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-secondary btn-sm"
                          style={{ float: "right" }}
                          onClick={() => {
                            navigate("/main/user_roles");
                          }}
                        >
                          Close Form
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UpdateUserRole;
