import React from "react";
import { Route, Routes } from "react-router-dom";
import Menu from "./Menu";
import Header from "./Header";
import Footer from "./Footer";
import Template from "./Template";
import Teams from "../pages/Teams/Teams";
import AuditTrail from "../pages/AuditTrail/AuditTrail";
import CreateTeam from "../pages/Teams/CreateTeam";
import UpdateTeam from "../pages/Teams/UpdateTeam";
import UserProfile from "../pages/Users/UserProfile";
import Roles from "../pages/Roles/Roles";
import CreateRole from "../pages/Roles/CreateRole";
import UpdateRole from "../pages/Roles/UpdateRole";

import CreateSurvey from "../pages/Survey/CreateSurvey";
import UpdateSurvey from "../pages/Survey/UpdateSurvey";
import UsersList from "../pages/Users/UsersList";
import Dashboard from "../pages/Dashboard/Dashboard";
import TeamMembers from "../pages/TeamMembers/TeamMembers";
import CreateTeamMember from "../pages/TeamMembers/CreateTeamMember";
import UpdateTeamMembers from "../pages/TeamMembers/UpdateTeamMember";
import AppConfig from "../pages/AppConfig/AppConfig";
import CreateAppConfig from "../pages/AppConfig/CreateAppConfig";
import UpdateAppConfig from "../pages/AppConfig/UpdateAppConfig";
import UserRole from "../pages/Roles/UserRole";
import UpdateUserRole from "../pages/Roles/UpdateUserRole";
import CreateUserRole from "../pages/Roles/CreateUserRole";
import Webinar from "../pages/Webinar/Webinar";
import Payment from "../pages/Payment/Payment";
import PaymentSucess from "../pages/Payment/PaymentSucess";
import PaymentFail from "../pages/Payment/PaymentFail";
import Transactions from "../pages/Payment/Transactions";
import UploadMembers from "../pages/TeamMembers/UploadMembers";
import Assessments from "../pages/Survey/Assessments";
import Survey from "../pages/Survey/Survey";
import AssessmentInfo from "../pages/Survey/AssessmentInfo";
import CreateAssement from "../pages/Survey/CreateAssessment";
import MemberAssessments from "../pages/Survey/MemberAssessments";
import CreateUser from "../pages/Users/CreateUser";
import UpdateProfile from "../pages/Users/UpdateProfile";
import CreateProfile from "../pages/Users/CreateProfile";
import CreateEmailConfig from "../pages/EmailConfig/CreateEmailConfig";
import EmailConfig from "../pages/EmailConfig/EmailConfig";
import UpdateEmailConfig from "../pages/EmailConfig/UpdateEmailConfig";


const Main = () => {
  
  return (
    <div style={{ overflowX: "hidden ", fontSize: "13px"}}>
      <Menu />
      <Header />
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/teams" element={<Teams />} />
        <Route path="/add_team" element={<CreateTeam />} />
        <Route path="/team_members" element={<TeamMembers />} />
        <Route path="/add_team_member" element={<CreateTeamMember />} />
        <Route path="/update_team_member/:id" element={<UpdateTeamMembers />} />
        <Route  path="/members_uplaod/:id" element={<UploadMembers />}/>
        <Route path="/user_profile" element={<UserProfile />}/>
        <Route path="/create_profile/:id" element={<CreateProfile />} />
        <Route path="/update_profile" element={<UpdateProfile />} />
        <Route path="/update_team/:id" element={<UpdateTeam />} />
        <Route path="/audit_trail" element={<AuditTrail />} />
        <Route path="/users" element={<UsersList />} />

        <Route path="/create_email_config" element={<CreateEmailConfig />} />
        <Route path="/update_email_config/:id" element={<UpdateEmailConfig />} />
        <Route path="/email_config" element={<EmailConfig />} />


        <Route path="/create_user" element={<CreateUser />} />

        <Route path="/roles" element={<Roles />} />
        <Route path="/add_role" element={<CreateRole />} />
        <Route path="/update_role/:id" element={<UpdateRole />} />


        <Route path="/surveys" element={<Survey />} />
        <Route path="/view_assessment/:id/" element={<AssessmentInfo />} />
        <Route path="/member_assessments" element={<MemberAssessments />}/>
        <Route path="/create_assessment" element={<CreateAssement />} />
        <Route path="/add_survey/:id" element={<CreateSurvey />} />
        <Route path="/update_survey/:id" element={<UpdateSurvey />} />
        <Route path="/assessments" element={<Assessments />} />
        <Route path="/appConfig" element={<AppConfig />}/>
        <Route path="/add_app_name" element={<CreateAppConfig />} />
        <Route path="/edit_app_name/:id" element={<UpdateAppConfig />} />

        <Route path="/user_roles" element={<UserRole />} />
        <Route path="/update_user_role/:id" element={<UpdateUserRole />} />
        <Route path="/add_user_role" element={<CreateUserRole />} />
        <Route path="/webinar" element={<Webinar />} />

        <Route path="/transactions" element={<Transactions />} />
        <Route  path="/payment_succesiful" element={<PaymentSucess />} />
        <Route  path="/payment_failure" element={<PaymentFail />}/>
        <Route  path="/payment/:id" element={<Payment />}/>

      </Routes>
      <Footer />
    </div>
  );
};

export default Main;
