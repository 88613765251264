import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

function AssessmentModal() {
  const [show, setShow] = useState(true);

  const USERROLE = localStorage.getItem("role");

  const handleClose = () => {
    setShow(false);
  };

  return (
    <div className="">
      <Modal
        style={{ minHeight: "700px", marginTop: "60px" }}
        dialogClassName="custom-modal"
        show={show}
        // onHide={handleClose}
        centered
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            padding: "7px"
          }}
        >
          <button className=" btn btn-outline-secondary btn-xs rounded" onClick={handleClose} >
            <i className="fas fa-times"></i>
          </button>
        </div>
        <Modal.Body
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "80px",
          }}
        >
          <Link style={{ width: "48%", height: "80px" }} to={`/main/webinar`}>
            <button
              className="app__bgColor btn text-white text-lg outline-none "
              style={{ width: "100%", height: "85%", color: "white" }}
            >
              Go to Webinar
            </button>
          </Link>
          {USERROLE === "Team Member" || USERROLE === "Admin" ? (
            <Link
              style={{ width: "48%", height: "80px" }}
              to={`/main/assessments`}
            >
              {" "}
              <button
                className="app__bgColor btn text-white text-lg  outline-none"
                style={{ width: "100%", height: "85%", color: "white" }}
              >
                Go to Assessment
              </button>
            </Link>
          ) : (
            <Link
              style={{ width: "48%", height: "80px" }}
              to={`/main/assessments`}
            >
              {" "}
              <button
                type="button"
                className="app__bgColor btn text-white text-lg"
                style={{ width: "100%", height: "85%" }}
              >
                Go To Assessment
              </button>
            </Link>
          )}
        </Modal.Body>

       
      </Modal>
    </div>
  );
}

export default AssessmentModal;
