import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../../../apiConfig";
import { Link } from "react-router-dom";
import Spinner from "../../common/Spinner";
import DeleteConfirm from "../../common/DeleteConfirm";
import PaymentModal from "../Payment/PaymentModal";
import useTokenExpiration from "../../../useTokenExpiration";

function Survey() {
  const [surveys, setSurveys] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [hasPaid, setHasPaid] = useState(false);
  const [selectedSurveyId, setSelectedSurveyId] = useState(null);
  const [show, setShow] = useState(false);

  const { errorMessage, handleTokenExpiry } = useTokenExpiration();

  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10;

  const totalPages = Math.ceil(surveys.length / itemsPerPage)


  const handlePrevPage = () => {
    if(currentPage > 1) {
      setCurrentPage(currentPage -1)
    }
  }

  const handleNextPage = () => {
    if(currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
    
  }

  const USERROLE = localStorage.getItem("role");

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleConfirmDelete = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/survey/delete_survey/${selectedSurveyId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      if(response.status === 401) {
        handleTokenExpiry()
        return
      }

      if (response.ok) {
        // getSurveys();
      } else {
        console.log("Server error");
      }
    } catch (error) {
      console.log("An error occurred while deleting survey", error);
    }
    setTimeout(() => {
      setShow(false);
    }, 1000);
  };

  const handleDelete = (surveyId) => {
    setSelectedSurveyId(surveyId);
    handleShow();
  };

  function convertToCSV(data) {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((row) => Object.values(row).join(","));
    return [header, ...rows].join("\n");
  }

  const getSurvey = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/api/survey/get_surveys/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      if(response.status === 401) {
        handleTokenExpiry()
        return
      }

      if (response.status === 200) {
        const data = await response.json();
        setSurveys(Array.isArray(data) ? data : []);
        setHasPaid(true);
      } else if (response.status === 403) {
        setHasPaid(false);
      }
    } catch (error) {
      console.log("An error occurred while fetching surveys");
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredSurveys = Array.isArray(surveys)
    ? surveys.filter((survey) => {
        return (
          survey.member.toString().toLowerCase().includes(searchText.toLowerCase()) || ""
        );
      })
    : [];

  useEffect(() => {
    getSurvey();
  }, []);

  if (loading) {
    return <Spinner />;
  }

  if (!hasPaid) {
    return <PaymentModal />;
  }

  return (
    <div className="content-wrapper" style={{ minHeight: "90vh" }}>
      <section className="content-header ">
        <div className="container-fluid ">
          <div className="row ">
            <div className="col-sm-6">
              <h5>Assessments</h5>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/main/dashboard">Home</a>
                </li>
                <li className="breadcrumb-item active">Assessments</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-12 ">
              <div className="card">
                <div className="card-body ">
                  <div className="container ">
                    <div className="row ">
                      <div className="col-sm-6">
                        <h3 className="card-title text-bold">Assessments</h3>
                      </div>
                      <div className="col-sm-6 text-right">
                        <Link
                          to={`/main/add_survey`}
                          type="button"
                          className="btn btn-outline-primary btn-xs"
                          style={{ float: "right", marginRight: "10px" }}
                        >
                          Take Assessment
                        </Link>
                        {(USERROLE === "Team Lead" || USERROLE === "Admin") && (
                          <button
                            type="button"
                            className="btn btn-outline-primary btn-xs"
                            style={{ float: "right", marginRight: "10px" }}
                            onClick={() => {
                              const csv = surveys.map((survey) => ({
                                Member: survey.member,
                                competence: survey.competence,
                                credibility: survey.credibility,
                                clarity: survey.clarity,
                                connectivity: survey.connectivity,
                              }));
                              const csvData = convertToCSV(csv);
                              const blob = new Blob([csvData], {
                                type: "text/csv",
                              });
                              const url = window.URL.createObjectURL(blob);
                              const a = document.createElement("a");
                              a.setAttribute("href", url);
                              a.setAttribute("download", "surveys.csv");
                              a.click();
                            }}
                          >
                            Export to CSV
                          </button>
                        )}

                        <input
                          type="text"
                          className="form-control form-control-sm"
                          placeholder="Search..."
                          value={searchText}
                          onChange={handleSearch}
                          style={{
                            width: "200px",
                            float: "right",
                            marginRight: "10px",
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  {errorMessage && (
                           <div className=" container mt-2 bg-danger">
                           <p className="p-2 ">{errorMessage}</p>
                           </div>
                      )}

                      
                  <div className="container">
                    <table className="table table-bordered w-100">
                      <thead>
                        <tr>
                          <th className="text-primary">Member</th>
                          <th className="text-primary">Competence</th>
                          <th className="text-primary">Credibility</th>
                          <th className="text-primary">Clarity</th>
                          <th className="text-primary">Connectivity</th>
                          {/* <th className="text-primary">Actions</th> */}
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            How good are they at what they do? (0 - 10) 10 is
                            the highest
                          </td>
                          <td>
                            How much do you trust them as a person? (0 - 10) 10
                            is the highest
                          </td>
                          <td>
                            How well do they communicate about their area of
                            expertise? (0 - 10) 10 is the highest
                          </td>
                          <td>
                            How well connected are they? (0 - 10) 10 is the
                            highest
                          </td>
                          <td></td>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredSurveys.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((survey) => (
                          <tr key={survey.id}>
                            <td>{survey.member}</td>
                            <td>{survey.competence}</td>
                            <td>{survey.credibility}</td>
                            <td>{survey.clarity}</td>
                            <td>{survey.connectivity}</td>
                           
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    <nav style={{display: "flex", alignItems: "center", justifyContent: 'end'}} aria-label="Page navigation example ">
                  <ul class="pagination">
                    <li class="page-item" onClick={handlePrevPage}><a class="page-link" href="#">Previous</a></li>
                   
                    <li class="page-item"><a class="page-link" href="#">Page {currentPage} of {totalPages}</a></li>
                    <li class="page-item" onClick={handleNextPage}><a class="page-link" href="#">Next</a></li>
                  </ul>
                </nav>
                  </div>

                 

                  <div className="container border rounded mt-5">
                    {show && (
                      <DeleteConfirm
                        handleConfirmDelete={handleConfirmDelete}
                        itemName={
                          surveys.find(
                            (survey) => survey.id === selectedSurveyId
                          )?.member
                        }
                        handleClose={handleClose}
                        handleShow={handleShow}
                        show={show}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Survey;
