import React from 'react'

const ActivateAccount = () => {
  return (
    <div className="d-flex align-items-center justify-content-center min-vh-100">
      <div className="login-container container mx-auto">
        <div className="d-flex align-items-center justify-content-center flex-column bg-white custom-card w-100 w-md-50 rounded p-4 shadow">
          <h5 className="text-center font-weight-bold my-4">
            Account activation
          </h5>
          <div className="w-100">
            <p>Thank you for creating account</p>
            <p>Please check your email address to activate your account</p>
           
          </div>

         
        </div>
      </div>
    </div>
  )
}

export default ActivateAccount