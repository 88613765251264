import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../../../apiConfig";
import DataTable from "react-data-table-component";
import Spinner from "../../common/Spinner";

import TableLoader from "../../common/TableLoader";
import useTokenExpiration from "../../../useTokenExpiration";

const AuditTrail = () => {
  const [auditTrails, setAuditTrails] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true)
  const { errorMessage, handleTokenExpiry } = useTokenExpiration();







  function convertToCSV(data) {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((row) => Object.values(row).join(","));
    return [header, ...rows].join("\n");
  }

  const getAuditTrails = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/api/accounts/audit_trail/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      if(response.status === 401) {
        handleTokenExpiry()
        return
      }

      const data = await response.json();
      setAuditTrails(data);
      console.log("Audit Trails", data);
    } catch (error) {
      console.log("an error occurred while fetching teams");
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredAuditTrails = auditTrails.filter((user) => {
    return (
      user.username.toLowerCase().includes(searchText.toLowerCase()) ||
      user.first_name.toLowerCase().includes(searchText.toLowerCase()) ||
      user.last_name.toLowerCase().includes(searchText.toLowerCase())||
      user.email.toLowerCase().includes(searchText.toLowerCase())
    );
  });

  const columns = [
    {
      name: "Username",
      selector: (row) => `${row.username}`,
      sortable: true,
      className: "bold-header",
    },
    {
      name: "First name",
      selector: (row) => `${row.first_name} `,
      sortable: true,
      className: "bold-header",
    },

    {
        name: "Last name",
        selector: (row) => `${row.last_name} `,
        sortable: true,
        className: "bold-header",
      },

      {
        name: "Email",
        selector: (row) => `${row.email} `,
        sortable: true,
        className: "bold-header",
      },

      {
        name: "Last Login",
        selector: (row) => `${row.last_login} `,
        sortable: true,
        className: "bold-header",
      },

    
  ];

  useEffect(() => {

    const timer = setTimeout(() => {
      getAuditTrails();
      setIsLoading(false)
    }, 1000);

    return () => clearTimeout(timer)

    
  }, []);

  return (
    <>
    {isLoading ? (
      <Spinner />
    ) : (
      <div className="content-wrapper" style={{minHeight: "90vh"}}>
      <section className="content-header ">
        <div className="container-fluid ">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h5>Last Login </h5>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/main/dashboard">Home</a>
                </li>
                <li className="breadcrumb-item active">Last Login</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-12 ">
              <div className="card">
                <div className="card-body ">
                  <div className="container   mt-5">
                    <div className="row ">
                      <div className="col-sm-6">
                        <h3 className="card-title text-bold">Last Login</h3>
                      </div>
                      <div className="col-sm-6 text-right">
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-xs"
                          style={{ float: "right", marginRight: "10px" }}
                          onClick={() => {
                            const csv = auditTrails.map((user) => ({
                              username: user.username,
                              Full_Name: `${user.first_name} ${user.last_name}`,
                              Email: user.email,
                              last_login: user.last_login
                            }));
                            const csvData = convertToCSV(csv);
                            const blob = new Blob([csvData], {
                              type: "text/csv",
                            });
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement("a");
                            a.setAttribute("href", url);
                            a.setAttribute("download", "last login.csv");
                            a.click();
                          }}
                        >
                          Export to CSV
                        </button>

                        <input
                          type="text"
                          className="form-control form-control-sm"
                          placeholder="Search..."
                          value={searchText}
                          onChange={handleSearch}
                          style={{
                            width: "200px",
                            float: "right",
                            marginRight: "10px",
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  {errorMessage && (
                           <div className=" container mt-2 bg-danger">
                           <p className="p-2 ">{errorMessage}</p>
                           </div>
                      )}

                  <div className="container border rounded mt-5">
                    <div>
                      {loading}
                      <DataTable
                        columns={columns}
                        data={filteredAuditTrails}
                        fixedHeader
                        responsive={true}
                        selectableRows
                        progressPending={loading}
                        progressComponent={<TableLoader />}
                        onTableUpdate={() => setLoading(false)} // Reset loading state when table updates
                        striped // Enable row striping
                        highlightOnHover // Highlight row
                        pagination // Paginate
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    )}
   
    </>
  );
}

export default AuditTrail;
