import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../../../apiConfig";

import { useNavigate, useParams } from "react-router-dom";

const UpdateEmailConfig = () => {
  const navigate = useNavigate();
  const [emailConfig, setEmailConfig] = useState({})
    const params = useParams()
    const [loading,setLoading] = useState(true)



  const [formData, setFormData] = useState({
    sender_email: "",
    password: "",
    subject: "",
    body:""

  });


  const getEmailConfig = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/app_config/email_config/${params.id}/`, {
        method: "GET",
        headers: {
           'Authorization': `Bearer ${localStorage.getItem("access_token")}`
        },
      });
      const data = await response.json();
     setFormData(data)
     
      console.log("Email Configs", data);
    } catch (error) {
      console.log("an error occurred while fetching email configs");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = async (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        `${API_BASE_URL}/app_config/email_config/update/${params.id}/`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },

          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        console.log("created succesifully");
        navigate("/main/email_config");

        setTimeout(() => {}, 3000);
      } else {
        console.log("An error occured while submitting data to the server");
        setTimeout(() => {}, 3000);
      }
    } catch (error) {
      console.log("An error occured while submitting data");
    }
  };

  useEffect(() => {
    getEmailConfig()
  }, [params.id])

  return (
    <div className="content-wrapper" style={{minHeight: "90vh"}}>
      <section className="content-header ">
        <div className="container-fluid ">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h5>Email Config </h5>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/main/dashboard">Home</a>
                </li>
                <li className="breadcrumb-item active">Create Email Config</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-12 ">
              <div className="card">
                <div className="card-body ">
                  <div className="container   mt-5">
                    <div className="row ">
                      <div className="col-sm-6">
                        <h3 className="card-title text-bold">Add Email Config</h3>
                      </div>
                      <div className="col-sm-6 text-right"></div>
                    </div>
                  </div>

                  <div className="container border rounded mt-5">
                    <div>
                      <form
                        onSubmit={handleSubmit}
                        style={{ margin: "0 10px" }}
                      >
                        <div className="mt-4">
                          <div>
                            <h6 className=" text-bold">Sender Email</h6>
                            <input
                              type="text"
                              className="form-control mb-2 rounded"
                              name="sender_email"
                              value={formData.sender_email}
                              onChange={handleChange}
                            />
                          </div>


                          <div className="mt-4">
                          <div>
                            <h6 className=" text-bold">Email Password</h6>
                            <input
                              type="text"
                              className="form-control mb-2 rounded"
                              name="password"
                              value={formData.password}
                              onChange={handleChange}
                            />
                          </div>
                          </div>


                          <div className="mt-4">
                          <div>
                            <h6 className=" text-bold">Email Body</h6>
                            <input
                              type="text"
                              className="form-control mb-2 rounded"
                              name="body"
                              value={formData.body}
                              onChange={handleChange}
                            />
                          </div>
                          </div>


                          <div className="mt-4">
                          <div>
                            <h6 className=" text-bold">Email Subject</h6>
                            <input
                              type="text"
                              className="form-control mb-2 rounded"
                              name="subject"
                              value={formData.subject}
                              onChange={handleChange}
                            />
                          </div>
                          </div>

                          

                         
                        </div>

                        <div className="card-footer">
                          <button
                            type="submit"
                            className="btn app__bgColor text-white  text-bold btn-sm"
                          >
                            Save Details
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-secondary btn-sm"
                            style={{ float: "right" }}
                            onClick={() => {
                              window.location.href = "/main/teams";
                            }}
                          >
                            Close Form
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UpdateEmailConfig;
