import React from "react";

const Homepage = () => {


    const btnStyles = {
        fontFamily: "Raleway, sans-serif",
        fontWeight: 600,
        fontSize: "13px",
        textTransform: "uppercase",
        letterSpacing: "1px",
        display: "inline-block",
        padding: "12px 28px",
        borderRadius: "3px",
        transition: "0.5s",
        color: "#fff",
        width: "280px",
        background: "#1b1553",
        border: "none",
        marginBottom: "3px"
        
      };

      
  return (
    <div>
      <div id="preloader">
        <div className="spinner"></div>
      </div>

      <header id="header" className="fixed-top d-flex align-items-center">
        <div className="container d-flex align-items-center">
          <div className="logo me-auto">
            <h1>
              <a href="index.html">
                <h5 style={{ color: "black" }}>Home</h5>
              </a>
            </h1>
          </div>

          <nav id="navbar" className="navbar order-last order-lg-0">
            <ul></ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>
        </div>
      </header>

      <section id="hero">
        <div className="container">
          <div className="row">
            <h3
              className=" my-5"
              style={{
                backgroundColor: "#1b1553",
                color: "white",
                textAlign: "center",
                width: "100%"
              }}
            >
              Network with The 4Cs Optimize Your Network. Maximize ROI.
            </h3>
          </div>
          <div className="row text-dark">
            <div
              className="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center text-md "
             
            >
              <div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <p>
                <i className="fas fa-check-circle"></i> Discover the 4Cs – &
                  why trust isn’t enough in relationship building.
                </p>
                <p>
                <i className="fas fa-check-circle"></i>Take the 4Cs
                  Peer-to-Peer Assessment. 100% Anonymous!{" "}
                </p>

                <p>
                <i className="fas fa-check-circle"></i> Take the 4Cs
                  Self-assessment!
                </p>
                <p>
                  {" "}
                  <i className="fas fa-check-circle"></i> Attend the upcoming 4Cs
                  Deep Dive Webinars
                </p>
                <p>
                  {" "}
                  <i className="fas fa-check-circle"></i>
                  Become a certified 4Cs expert (coming soon)
                </p>

                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>

            <div className="col-lg-3 order-1 order-lg-1 ">
              <br />
              <br />
              <br />
              <h4 style={{ textAlign: "center" }}>
                <strong>
                  Based on the <br />
                  Amazon best selling
                  <br />
                </strong>
              </h4>
              <img
                src="/fourc.jpg"
                className="img-fluid  img-right"
                alt=""
                height="100"
              />
            </div>
          </div>
          <div className="row my-5">
            <div className="container-fluid">
              <div className="row">
              <div className="col-auto">
                  <button
                   style={btnStyles}
                  >
                    <a
                      href="https://networkwiththe4cs.com/signin"
                      role="button"
                      onclick=""
                      className="text-white"
                      style={{textDecoration: 'none'}}
                    >
                      <span className="spinner" id="button-spinner"></span> Self
                      assessment
                    </a>
                  </button>
                </div>

                <div className="col-auto">
                  <button
                   style={btnStyles}
                  >
                    <a
                      href="https://networkwiththe4cs.com/signin"
                      role="button"
                      onclick=""
                      className="text-white"
                      style={{textDecoration: 'none'}}
                    >
                      <span className="spinner" id="button-spinner"></span> Self
                      assessment
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <h4>Coming up soon!</h4>
            </div>

            <button
              type="button"
              className="btn btn-secondary"
              style={{ backgroundColor: "#1b1553" }}
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
