import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../../../apiConfig';


const CreateAppConfig = () => {
    const navigate = useNavigate()
   

    const [appName, setAppName] = useState({
        app_name: '',
        email: "",
        success_url: "",
        cancel_url: "",
        login_url: "",
        signup_url: "",
        base_url: "",
        stripe_publishable_key: "",
        stripe_secret_key: "",
        stripe_endpoint_secret: ""
        
    })


    const handleChange = async(event) => {
        const {name, value} = event.target;
        setAppName({
            ...appName,
            [name]: value
        })
    }


    const handleSubmit = async(event) => {
        event.preventDefault()

        try {
            const response = await fetch(`${API_BASE_URL}/app_config/app_configs/create/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },

                body: JSON.stringify(appName)

            })

            if(response.ok) {
                console.log("created succesifully")
               
                navigate('/main/appConfig')
                window.location.reload()
                
                setTimeout(() => {
                 
                }, 3000)

      
               
                

            } else {
                console.log("An error occured while submitting data to the server")
                setTimeout(()=> {
                
                }, 3000)
                
               
            }

        } catch(error) {
            console.log('An error occured while submitting data')
   
        }
    }
  return (
    <div className="content-wrapper" style={{ minHeight: "90vh", padding: "20px" }}>
    <section className="content-header">
      <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Enter App Name</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item"><a href="/main/dashboard">Home</a></li>
                <li className="breadcrumb-item active">App Config Form</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      {/* General form elements */}
      <div className="card py-4" >
        <div className="card-header">
          <h3 className="card-title text-bold">App Configuration</h3>
        </div>
        <div className="container bordershadow-lg rounded mt-2">
        <form onSubmit={handleSubmit} style={{ margin: '0 10px' }}>
         
          
          <div className="mt-4 ">
                <div>
                    <h6 className=' text-bold'>App Name</h6>
                    <input
                        type='text'
                        className='form-control mb-2 rounded'
                        name='app_name'
                        value={appName.app_name}
                        onChange={handleChange}
                    />
                </div>


                <div>
                    <h6 className=' text-bold'>Organization/Company email</h6>
                    <input
                        type='text'
                        className='form-control mb-2 rounded'
                        name='email'
                        value={appName.email}
                        onChange={handleChange}
                    />
                </div>

                <div>
                    <h6 className=' text-bold'>Success URL</h6>
                    <input
                        type='text'
                        className='form-control mb-2 rounded'
                        name='success_url'
                        value={appName.success_url}
                        onChange={handleChange}
                    />
                </div>


                <div>
                    <h6 className=' text-bold'>Cancel URL</h6>
                    <input
                        type='text'
                        className='form-control mb-2 rounded'
                        name='cancel_url'
                        value={appName.cancel_url}
                        onChange={handleChange}
                    />
                </div>

                <div>
                    <h6 className=' text-bold'>Login URL</h6>
                    <input
                        type='text'
                        className='form-control mb-2 rounded'
                        name='login_url'
                        value={appName.login_url}
                        onChange={handleChange}
                    />
                </div>

                <div>
                    <h6 className=' text-bold'>Signup URL</h6>
                    <input
                        type='text'
                        className='form-control mb-2 rounded'
                        name='signup_url'
                        value={appName.signup_url}
                        onChange={handleChange}
                    />
                </div>

                <div>
                    <h6 className=' text-bold'>Base URL</h6>
                    <input
                        type='text'
                        className='form-control mb-2 rounded'
                        name='base_url'
                        value={appName.base_url}
                        onChange={handleChange}
                    />
                </div>

               

                <div>
                    <h6 className=' text-bold'>STRIPE SECRET KEY</h6>
                    <input
                        type='text'
                        className='form-control mb-2 rounded'
                        name='stripe_secret_key'
                        value={appName.stripe_secret_key}
                        onChange={handleChange}
                    />
                </div>
                

                <div>
                    <h6 className=' text-bold'>STRIPE PUBLISHABLE KEY</h6>
                    <input
                        type='text'
                        className='form-control mb-2 rounded'
                        name='stripe_publishable_key'
                        value={appName.stripe_publishable_key}
                        onChange={handleChange}
                    />
                </div>

                <div>
                    <h6 className=' text-bold'>STRIPE_ENDPOINT_SECRET</h6>
                    <input
                        type='text'
                        className='form-control mb-2 rounded'
                        name='stripe_endpoint_secret'
                        value={appName.stripe_endpoint_secret}
                        onChange={handleChange}
                    />
                </div>
                
                
                
          </div>

         

          <div className="card-footer">
            <button type="submit" className="btn app__bgColor text-white text-bold">Save Details</button>
            <button
              type="button"
              className="btn btn-outline-secondary"
              style={{ float: 'right' }}
              onClick={() => { window.location.href = '/main/appConfig'; }}
            >
              Close Form
            </button>

            
           
          </div>
        </form>
      </div>
      </div>
    
    </div>
  );
};

export default CreateAppConfig;
