import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Main from './components/common/Main';
import Signin from './components/Signin';
import Signup from './components/Signup';
import ActivateAccount from './components/ActivateAccount';
import HomePage from './components/Home/Homepage';

function App() {
  return (
    <div className=" app-container">
     <BrowserRouter>
     <Routes>
      <Route path='/signin' element={<Signin />} />
      <Route path='/' element={<HomePage />} />
      <Route path='/signup' element={<Signup />} />
      <Route path='/activate_account' element={<ActivateAccount />} />
      <Route path='/main/*' element={<Main />} />
     </Routes>
     </BrowserRouter>
    </div>
  );
}

export default App;
